<template>
  <transition
    name="fade-block-wait"
    appear
    mode="out-in"
  >
    <div
      v-shortkey="['esc']"
      class="modals-schedule"
      @contextmenu.self.prevent
      @click="showModalScheadule = false"
      @shortkey="showModalScheadule = false"
    >
      <div
        class="modals-schedule__content"
        @click.stop
        @contextmenu.prevent
      >
        <div
          class="modals-schedule__close"
          @click="showModalScheadule = false"
        >
          <i class="icon-close-modal"></i>
        </div>
        <!--      <div class="meetings-iframe-container" data-src="https://app.hubspot.com/meetings/yuri?embed=true"></div>-->
        <div
          class="meetings-iframe-container"
        >
          <div
            class="calendly-inline-widget"
            :data-url="
              isPrayerwall ?
                'https://calendly.com/chadkellogg/prayer_platform_meeting'
                : 'https://calendly.com/d/cmv-mf2-9zh/demo-of-impactfactors-vip-50-discount?month=2022-01'
            "
          >
          </div>
        </div>
        <!--      <div class="modals-schedule__title">-->
        <!--        Wait!-->
        <!--      </div>-->
      </div>
    </div>
  </transition>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  name: 'ModalsSchedule',
  components: {
    VuePerfectScrollbar,
  },
  props: {
    isPrayerwall: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    showModalScheadule: {
      get() {
        return this.$store.getters.showModalScheadule;
      },
      set(data) {
        this.$store.commit('setShowModalScheadule', data);
      },
    },
  },
  beforeMount() {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);
  },
  methods: {
  },
};
</script>

<style lang="scss">
  @import '../../assets/sass/utils/variables';

  .modals-schedule {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    /*transition: opacity .5s ease-in;*/
    background: rgba(0, 0, 0, .8);
    /*&__bg {*/
      /*background: rgba(0, 0, 0, 0);*/
      /*animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;*/
    /*}*/

    &__content {
      max-width: 1092px;
      width: 100%;
      background: $color-white;
      border-radius: 10px;
      position: relative;
      padding: 15px;
      height: 731px;
      max-height: 100%;
      overflow: hidden;

      @media (max-width: 767px) {
        border-radius: 0;
      }

    }

    &__close {
      position: absolute;
      top: 23px;
      right: 16px;
      font-size: 16px;
      color: $color-jumbo;
      transition: transform .15s ease-in;
      cursor: pointer;
      z-index: 2;

      &:hover {
        transform: rotate(90deg);
      }
    }
  }

  @keyframes scaleUp {
    0% {
      transform: translateY(1000px);
      opacity:0;
    }
    100% {
      transform: translateY(0);
      opacity:1;
    }
  }
  @keyframes scaleDown {
    0% {
      transform: translateY(0);
      opacity:1;
    }
    100% {
      transform: translateY(1000px);
      opacity:0;
    }
  }

  .meetings-iframe-container {

    @media (max-width: 767px) {
      height: 100%;
    }

  }

  .calendly-inline-widget {
    min-width:320px;
    height: 670px;
    max-height: 100%;

    @media (max-width: 767px) {
      height: 1200px;
    }

  }
</style>
