<template>
  <div class="design">
    <div class="container">
      <h2 class="design__title">
        Custom services. Your design
      </h2>
      <div class="design__description description">
        <template v-if="index2">
          <span>You can use one of our free templates, or we can create a custom design for you.</span>
          <span>Also, you can provide a design, and we will program it for you.</span>
        </template>
        <template v-else>
          <span>Your designer can create a custom design in our CMS, or we can program it for you.</span>
          <span>We will be more than happy to work with you on any custom design requests until you are satisfied.</span>
        </template>
      </div>
      <div
        v-if="!index2"
        class="select"
        :class="{'select_open': isOpen}"
      >
        <div
          class="select__input"
          @mouseover.stop="isOpen = true"
          @mouseout.stop="isOpen = false"
        >
          <!---->
          {{ inputText }}
          <VuePerfectScrollbar
            class="select__drop"
          >
            <div class="select__drop-list">
              <div
                v-for="(option, index) in optionsList"
                :key="index"
              >
                <a
                  :href="option.href"
                  @click="openSchedule(index)"
                >
                  {{ option.text }}
                </a>
              </div>
            </div>
          </VuePerfectScrollbar>
        </div>
      </div>

      <div class="design__img">
        <img
          src="../assets/images/design__img.png"
          alt=""
        >
        <!--        <video autoplay muted loop id="myVideo">-->
        <!--          <source src="../assets/video/banner-hq.mp4" type="video/mp4">-->
        <!--        </video>-->
      </div>
      <div class="design-list-wrap">
        <div class="design-list">
          <a
            href=""
            class="design-list__links design-list__links_red"
            @click.prevent="scrollTo(windowEl, scrollToElem(), 300)"
          >
            <span>See what’s included + pricing</span>
            <span>for other services</span>
          </a>
          <a
            href=""
            class="design-list__links design-list__links_yellow"
            @click.prevent="showModalNewProject = true"
          >
            <span>See how your website</span>
            <span>can look like TODAY!</span>
          </a>
          <a
            href=""
            class="design-list__links design-list__links_blue"
            @click.prevent="showModalGetStarted = true"
          >
            <span>Get Started</span>
            <span>While this offer lasts!</span>
          </a>
        </div>
      </div>
    </div>
    <transition
      name="fade-block-wait"
      appear
      mode="out-in"
    >
      <modals-schedule
        v-if="showModalScheadule"
      >
      </modals-schedule>
    </transition>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import modalsSchedule from '@/components/modals/modalsSchedule';

export default {
  name: 'Design',
  components: {
    VuePerfectScrollbar,
    modalsSchedule,
  },
  props: [
    'index2',
  ],
  data() {
    return {
      windowEl: window,
      isOpen: false,
      inputText: 'Just contact us',
      optionsList: [
        {
          text: 'Send an Email Request',
          href: 'mailto:sales@impactfactors.net',
        },
        {
          text: 'Schedule a Phone Meeting',
        },
      ],
    };
  },
  computed: {
    showModalNewProject: {
      get() {
        return this.$store.getters.showModalNewProject;
      },
      set(data) {
        this.$store.commit('setShowModalNewProject', data);
      },
    },
    showModalGetStarted: {
      get() {
        return this.$store.getters.showModalGetStarted;
      },
      set(data) {
        this.$store.commit('setShowModalGetStarted', data);
      },
    },
    showModalScheadule: {
      get() {
        return this.$store.getters.showModalScheadule;
      },
      set(data) {
        this.$store.commit('setShowModalScheadule', data);
      },
    },
  },
  methods: {
    openSchedule(index) {
      if (index === 1) {
        this.showModalScheadule = true;
      } else {
        this.isOpen = false;
      }
    },
    scrollToElem() {
      return document.querySelector('#bundles').offsetTop;
    },
    scrollTo(element, to, duration, clear) {
      if (duration === 0) {
        return;
      }
      if (to < 0) to = 0;
      const difference = to - element.scrollY;
      const perTick = difference / duration * 10;
      const thisEl = this;
      if (clear) {
        clearTimeout(this.setTimeoutId);
      }
      this.setTimeoutId = setTimeout(() => {
        let newTop = element.scrollY + perTick;
        if (newTop < 0) newTop = 0;
        element.scrollTo({
          top: newTop,
        });
        if (newTop === 0) return false;
        if (element.scrollY === to) return;
        thisEl.scrollTo(element, to, duration - 10);
      }, 10);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../assets/sass/utils/variables';

  .select {
    position: relative;
    height: 40px;
    //background: $color-mountain-meadow;
    background: none;
    max-width: 239px;
    width: 100%;
    margin: auto;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: $color-white;
    border-radius: 4px;
    transition: background-color .2s ease-in;
    user-select: none;
    left: 6px;
    top: -4px;

    /*&:before {
      content: "\E804";
      font-family: $icon-font;
      position: absolute;
      right: 21px;
      top: 12px;
      font-size: 12px;
      transition: transform .15s ease;
      color: $color-mountain-meadow;
    }*/

    &__input{
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 4px;
      background-color: transparent;
      padding: 5px 24px 5px 14px;
      cursor: pointer;
      transition: border-color .2s ease-in;
      user-select: none;
      color: $color-mountain-meadow;
      font-size: 24px;
      font-family: $font-global-bold, sans-serif;
    }

    &__drop {
      opacity: 0;
      pointer-events: none;
      position: absolute !important;
      top: 100%;
      left: 0;
      z-index: 5;
      width: 100%;
      height: auto !important;
      max-height: 195px !important;
      /*margin-top: 5px;*/
      overflow: hidden;
      border-radius: 3px;
      padding: 4px 0;
      border: none;
      background: $color-white;
      box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
      color: $color-shark;
      transition: all .2s ease-in;
      font-family: $font-global;

      &-list {
        padding: 10px 17px;

        div {
          margin-bottom: 20px;
          transition: opacity .15s ease-in;

          /*&:hover {*/
          /*  opacity: .5;*/
          /*}*/

          &:last-child {
            margin-bottom: 0;
          }
        }

        a {
          text-decoration: none;
          color: $color-white;
          font-size: 18px;
        }
      }
    }

    &_open {
      background: transparent;

      .select__input {
        color: $color-mountain-meadow;
      }

      &:before {
        color: $color-mountain-meadow;
      }

      .select__drop {
        opacity: 1;
        pointer-events: all;
        background: $color-mountain-meadow;
      }
    }
  }

  .design {
    background: $color-cod-gray;
    padding-bottom: 80px;
    padding-top: 80px;
    position: relative;
    overflow: hidden;

    &__img {
      display: block;
      margin: 0 -100px;
      position: relative;
      top: -5px;
      margin-bottom: 55px;

      video {
        width: 100%;
      }

    }

    &__title {
      color: $color-white;
    }

    &__description {
      color: $color-jumbo;
      margin-top: 9px;

      span {
        display: block;
      }
    }

    &-list {
      display: flex;
      margin: 0 -30px;

      &-wrap {
        width: 100%;
      }

      &__links {
        max-width: 360px;
        width: 100%;
        margin: 0 15px;
        min-height: 88px;
        display: flex;
        color: $color-white;
        padding-left: 15px;
        line-height: 31px;
        font-size: 21px;
        border-radius: 5px;
        letter-spacing: .3px;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        position: relative;
        text-decoration: none;
        cursor: pointer;
        transition: background-position .2s ease-in;
        background-size: 200% auto !important;

        &:hover {
          background-position: right center;
        }

        span {
          display: block;
        }

        &:before {
          content: "\E804";
          font-family: fontello;
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%) rotate(-90deg);
          color: $color-white;
          font-size: 16px;
          transition: transform .15s ease;
        }

        &_red {
          background: linear-gradient(to right, $color-cranberry 0%, $color-burnt-sienna 100%);
        }

        &_yellow {
          background: linear-gradient(to right, $color-rajah 0%, $color-rajah-second 100%);
        }

        &_blue {
          background: linear-gradient(to right, $color-viking 0%, $color-moody-blue 100%);
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .design {
      &-list {
        &__links {
          font-size: 20px;

          &:before {
            right: 10px;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .design {

      &__img {
        margin: 0 -80px;
        margin-bottom: 50px;
      }
      &-list {
        margin: 0px -15px;
        &__links {
          font-size: 18px;
          margin: 0 5px;

          &_red {
            line-height: 24px;

            span {
              padding-right: 10px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .design {
      &-list {
        flex-direction: column;
        align-items: center;

        &__links {
          margin-bottom: 8px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .design {
      padding-top: 43px;
      padding-bottom: 25px;

      &__img {
        margin: 0 -15px;
        margin-bottom: 30px;
      }

      &__description {
        margin-top: 19px;
      }

      &-list {

        &__links {
          max-width: 313px;
          width: 100%;
          min-height: 60px;
          font-size: 21px;
          line-height: 31px;

          &:before {
            right: 8px;
          }

          &_red {
            min-height: 63px;
          }

          &_yellow {
            min-height: 66px;
          }

          &_blue {
            min-height: 68px;
          }
        }
      }
    }
    .select {
      max-width: 100%;
      text-align: center;
      left: 0;

      &__input {
        font-size: 16px;
        line-height: 24px;
        padding: 3px 0 0 0;
      }

      &__drop {
        width: 50%;
        left: 50%;
        transform: translateX(-50%);

        &-list {
          padding: 5px 10px;

          div {
            margin-bottom: 5px;
          }

          a {
            font-size: 14px;
          }
        }
      }
    }
  }
</style>
