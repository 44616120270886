<template>
  <div id="container">
    <div
      id="next-generation"
      class="next-generation"
      :class="{'next-generation_hide-video': hideVideoBlock}"
    >
      <template v-if="!hideVideoBlock">
        <video
          loop
          muted
          autoplay
          playsinline
          class="fullscreen-bg__video fullscreen-bg__video_pc"
        >
          <source
            src="../assets/video/if_intro.mp4"
            type="video/mp4"
          >
        </video>
        <video
          loop
          muted
          autoplay
          playsinline
          class="fullscreen-bg__video fullscreen-bg__video_mob"
        >
          <source
            src="../assets/video/if_intro-mob.mp4"
            type="video/mp4"
          >
        </video>
      </template>
      <div class="container">
        <div class="next-generation-content">
          <div class="next-generation-left">
            <h1 class="next-generation__title">
              <span>Next Generation</span>
              <span>Websites for</span>
              <span>Churches &</span>
              <span>Ministries</span>
            </h1>
          </div>
          <div
            v-if="!hideVideoBlock"
            class="next-generation__img-play"
            @click.prevent="setShowModalVideo()"
          >
            <img
              src="../assets/images/btn-play.png"
              alt=""
            >
          </div>
        </div>
        <div
          v-if="$route.name !== 'pricingPage'"
          class="actions"
        >
          <template v-if="$route.name !== 'index2'">
            <button
              class="btn actions__btn actions__btn_get"
              @click.prevent="showModalGetStarted = !showModalGetStarted"
            >
              Get Started
            </button>
            <button
              class="btn actions__btn actions__btn_try"
              @click="showModalNewProject = true"
            >
              Try Demo
            </button>
          </template>
          <button
            v-else
            class="btn actions__btn actions__btn_get"
            :style="{
              width: 'auto!important',
              padding: '0 15px'
            }"
            @click="scrollToForm()"
          >
            Schedule a Demo
          </button>
        </div>
        <div class="next-generation__text">
          Starting from only
          <span>$25/month</span>.
          Go Live in 25 minutes.
          <span>Limited Time Offer!</span>
        </div>
        <div
          v-if="!hideVideoBlock"
          class="next-generation__sign"
        >
          No setup fees. No contract. No hidden fees. No technical skills required
        </div>
      </div>
    </div>
    <powering-ministries />
    <innovative />
  </div>
</template>

<script>
import innovative from '@/components/innovative';
import poweringMinistries from '@/components/poweringMinistries';
import MobileDetect from 'mobile-detect';

export default {
  name: 'NextGenaration',
  components: {
    innovative,
    poweringMinistries,
  },
  props: ['showModalVideo', 'hideVideoBlock'],
  data() {
    return {
      shapeAnimation: true,
    };
  },
  computed: {
    showModalNewProject: {
      get() {
        return this.$store.getters.showModalNewProject;
      },
      set(data) {
        this.$store.commit('setShowModalNewProject', data);
      },
    },
    showModalGetStarted: {
      get() {
        return this.$store.getters.showModalGetStarted;
      },
      set(data) {
        this.$store.commit('setShowModalGetStarted', data);
      },
    },
  },
  methods: {
    scrollTo(element, to, duration, clear) {
      if (duration === 0) {
        return;
      }
      if (to < 0) to = 0;
      const difference = to - element.scrollY;
      const perTick = difference / duration * 10;
      if (clear) {
        clearTimeout(this.setTimeoutId);
      }
      this.setTimeoutId = setTimeout(() => {
        let newTop = element.scrollY + perTick;
        if (newTop < 0) newTop = 0;
        element.scrollTo({
          top: newTop,
        });
        if (newTop === 0) return false;
        if (element.scrollY === to) return;
        this.scrollTo(element, to, duration - 10);
      }, 10);
    },
    scrollToForm() {
      const anchorBlock = document.getElementById('hubspotFormContainer');
      this.scrollTo(window, anchorBlock.offsetTop, 500, 'clear');
    },
    setShowModalVideo() {
      console.log('setShowModalVideo');
      window.fbq('trackCustom', 'Watch Video');
      this.$emit('update:showModalVideo', true);
      setTimeout(() => {
        // console.log(document.getElementsByClassName('vjs-play-control')[0])
        // document.getElementsByClassName('vjs-play-control')[0].click()
      }, 1000);
    },
    escape(event) {
      // console.log('Esc key pressed.', `Event: ${event}`)
      // console.log('dsfsdfsdsdf')
    },
  },
  /* eslint-disable */
  mounted: function () {
    let md = new MobileDetect(window.navigator.userAgent)
    let mobile = md.mobile()
    let tablet = md.tablet()
    if (mobile !== null || tablet !== null) {
      // document.querySelector('.scene').classList.add('scene_hide')
      document.querySelector('.next-generation').classList.add('next-generation_not-vh')
    } else {
      document.querySelector('.next-generation').classList.remove('next-generation_not-vh')
    }
    this.$nextTick(() => {
      if (!this.hideVideoBlock) {
        document.querySelector('video').defaultPlaybackRate = 0.8
        document.querySelector('video').playbackRate = 0.8
      }
      let wow = new window.WOW(
        {
          boxClass: 'wow',
          animateClass: 'animated',
          offset: 0,
          mobile: true,
          live: false,
          scrollContainer: null
        }
      )
      wow.init()
      // setTimeout(() => {
      //   window.scrollTo(0, 0)
      //   console.log(window.scrollY)
      // }, 1000)
    })
  }
}
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';
  @import '../assets/sass/utils/transition';

  .fullscreen-bg__video {
    &_mob {
      display: none;
    }
  }

  .next-generation {
    margin-top: -126px;
    /*padding-top: 211px;*/
    /*height: calc(121vh);*/
    /*padding-top: 210px;*/
    /*height: calc(203vh);*/
    padding-top: 215px;
    /*height: calc(121vh);*/
    height: 100vh;
    display: flex;
    align-items: center;
    background: $color-concrete;
    overflow: hidden;
    position: relative;
    /*background: url("../assets/video/if_intro.gif") no-repeat center center fixed;*/
    /*background-size: cover;*/
    /*background-color: rgba(0,0,0, .8);*/
    /*display: none;*/

    &_hide-video {
      height: auto!important;
      background: url('../assets/images/design-slide1.jpg')!important;

      .next-generation-content {
        margin-bottom: 0;
      }

    }

    video {
      //top: -126px;
      top: 0;
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 50;
      /*background: url('../assets/images/line-pc.png') repeat;*/
      /*background: repeating-linear-gradient(*/
      /*    45deg,*/
      /*    rgba(0,0,0, .8),*/
      /*    rgba(0,0,0, .8) 7px,*/
      /*    transparent 1px,*/
      /*    rgba(0,0,0, .8) 8px*/
      /*);*/
      /*background-image: url('../assets/images/line1.png');*/
      background-color: rgba(0,0,0, .8);
    }

    &_not-vh {
      height: 100%;
    }

    .container {
      position: relative;
      z-index: 800;
      /*padding-top: 148px;*/
      padding-bottom: 80px;
    }

    /*.scene {
      max-width: 90%;

      &-block {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        overflow: hidden;
        max-width: 100vw;
        width: 100%;
        height: calc(100vh - 126px);
        z-index: 700;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &_hide {
        display: none;
      }
    }*/

    &__text {
      line-height: 40px;
      font-size: 31px;
      text-align: center;
      margin-bottom: 8px;
      color: $color-white;

      span {
        &:first-child {
          font-family: $font-global-medium, sans-serif;
        }
      }
    }

    &__sign {
      font-family: $font-global-medium, sans-serif;
      line-height: 31px;
      font-size: 21px;
      text-align: center;
      color: $color-white;
    }

    &__title {
      text-align: center;
      color: $color-white;
      /*font-size: 79px;*/
      /*line-height: 87px;*/
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.754289);
      font-family: $font-global-black, sans-serif;

      span {
        display: block;
      }
    }

    &__img {

      &-block {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      position: relative;
      top: 40px;
      max-width: 527px;

      &-play {
        cursor: pointer;
        transition: opacity .2s ease-in;
        max-width: 135px;

        &:hover, &:focus {
          opacity: .7;
        }
      }
    }

    &__desc {
      font-family: $font-second, sans-serif;
      color: $color-dusty-gray-second;
      font-size: 20px;
      position: relative;
      top: -4px;
    }

    &-content {
      display: flex;
      justify-content: center;
      margin-bottom: 100px;
      flex-direction: column;
      align-items: center;
    }

    &-right {
      max-width: 555px;
      min-height: 383px;
      width: 100%;
      background: $color-white;
      position: relative;
      top: -11px;
      left: 15px;
      box-shadow: 16px 41px 76px $color-blizzard-blue;
      border-radius: 12px;
    }
  /*073100790*/

    &-left {
      /*width: 50%;*/
      position: relative;
      margin-bottom: 100px;
    }
  }

  .fade-modal {
    &-enter-active {
      transition: .2s ease-in;
      .modals-video__content {
        animation: scaleUp .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
    &-leave-active {
      transition: .2s ease-in;

      .modals-video__content {
        animation: scaleDown .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
      }
    }
    &-enter, &-leave-to {
      opacity: 0;

      .modals-video__content {
        opacity: 0;
        transform: scale(0);
      }
    }
  }

  /*#container {*/
  /*  perspective: 300px;*/
  /*}*/

  #inner {
    transition: transform .15s;
  }

  @media (max-width: 1199px) {
    .next-generation {
      &__text {
        font-size: 26px;
        line-height: 36px;
      }

      &__sign {
        line-height: 24px;
        font-size: 18px;
      }

      &__img-play {
        max-width: 80px;
      }
    }
  }

  @media (max-width: 991px) {
    .next-generation {
      padding-top: 0;
      height: 100vh;

      &-left {
        margin-bottom: 50px;
      }

      .container {
        padding-top: 50px;
        padding-bottom: 50px;
      }

      &-content {
        margin-bottom: 50px;
      }

      &__text {
        margin-top: 30px;
        font-size: 22px;
      }

      &__sign {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 767px) {
    .fullscreen-bg__video {
      &_mob {
        display: block !important;
      }

      &_pc {
        display: none;
      }
    }

    .next-generation {
      height: auto;
      padding-top: 60px;
      margin-top: 0;

      video {
        width: auto;
      }

      &:before {
        /*background: url('../assets/images/line-mob.png') repeat;*/
        background-color: rgba(0,0,0, .8);
      }

      &-content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &-right {
        background: transparent;
        box-shadow: none;
        top: 0;
        left: 0;
      }

      &-left {
        left: 0;
      }

      &__title {
        text-align: center;
      }

      &__desc {
        display: none;
      }
    }
  }

  @media (max-width: 480px) {
    .next-generation {
      padding-top: 30px;

      &__img-play {
        max-width: 60px;
      }

      .container {
        padding-bottom: 23px;
      }

      &__text {
        line-height: 26px;
        font-size: 18px;
        margin-bottom: 15px;
      }

      &__sign {
        font-size: 14px;
        line-height: 20px;
      }
      /*&-right {
        top: -3px;
        left: -12px;
        min-height: 250px;
        max-width: 355px;
      }*/

      /*&__img {*/
      /*  &-play {*/
      /*    width: 70px;*/
      /*    height: 70px;*/
      /*    left: 55% !important;*/
      /*    top: 60px;*/
      /*    font-size: 26px;*/
      /*    padding-left: 7px;*/
      /*  }*/
      /*}*/
    }
  }

  @media screen and (max-height: 820px) and (max-width: 1400px) and (min-width: 1000px) and (min-height: 600px) {
    .next-generation {

      &__img-play {
        max-width: 90px;
      }
    }

    &-content {
      margin-bottom: 70px;
    }

    &-left {
      margin-bottom: 70px;
    }

    h1 {
      font-size: 60px;
      line-height: 65px;
    }
  }

  @media screen and (max-height: 730px) and (max-width: 1440px) and (min-width: 768px) and (min-height: 599px) {
    .next-generation {
      padding-top: 400px;

      &__img-play {
        max-width: 90px;
      }
    }

    &-content {
      margin-bottom: 70px;
    }

    &-left {
      margin-bottom: 70px;
    }

    h1 {
      font-size: 60px;
      line-height: 65px;
    }
  }

</style>

<style lang="scss" scoped>
  @import '../assets/sass/utils/variables';
  @import '../assets/sass/utils/transition';

  .actions {
    /*display: flex;*/
    align-items: center;
    justify-content: center;
    display: none;

    &__btn {
      transition: all .14s ease;

      &_get {
        height: 40px;
        background: $color-moody-blue;
        font-family: $font-global-medium, sans-serif;
        font-size: 16px;
        display: inline-block;
        color: $color-white;
        padding: 0 6px;
        border: 1px solid transparent;
        width: 137px;
        transition: opacity .2s ease-in, background .2s ease-in, color .2s ease-in;
        user-select: none;

        &:hover {
          opacity: .8;
        }

        &:active {
          background: $color-martinique;
          opacity: 1;
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 10px;
          height: 10px;
          background: rgba(255, 255, 255, .5);
          opacity: 0;
          border-radius: 100%;
          transform: scale(1, 1) translate(-50%);
          transform-origin: 50% 50%;
        }

        @keyframes ripple {
          0% {
            transform: scale(0, 0);
            opacity: 1;
          }
          20% {
            transform: scale(25, 25);
            opacity: 1;
          }
          100% {
            opacity: 0;
            transform: scale(40, 40);
          }
        }

        &:focus:not(:active)::after {
          animation: ripple 1.5s ease-out;
        }
      }

      &_try {
        border-color: $color-moody-blue;
        color: $color-royal-blue;
        width: 137px;
        height: 40px;
        font-size: 16px;

        &:hover {
          color: $color-white;
          background: $color-moody-blue;
        }
      }

      &:last-child {
        margin-left: 16px;
      }
    }
  }

  @media (max-width: 991px) {
    .actions {
      display: flex;
    }
  }
</style>
