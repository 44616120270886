<template>
  <div
    :id="formContainerId"
    class="hubspot-form"
    :class="{'hubspot-form_no-from': !appendForm}"
  >
    <div class="container">
      <h2 class="hubspot-form__title">
        <span>One Time COVID 19 Discount</span>
        <span>Talk to our product expert!</span>
      </h2>
      <div class="hubspot-form__sub-title description">
        VIP discount for the first 50 churches to sign up. Unbeatable pricing.
      </div>
      <div
        class="hubspot-form__content"
        :class="{'hubspot-form__content_btn': !appendForm}"
      >
        <div
          v-if="appendForm"
          id="hubspotForm"
        />
        <button
          v-else
          class="btn actions__btn actions__btn_get"
          @click="scrollToForm()"
        >
          Schedule a Demo
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HubspotForm',
  props: {
    formContainerId: {
      type: String,
      default: 'hubspotFormContainer',
    },
    appendForm: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.appendForm) {
        const script = document.createElement('script');
        script.innerHTML = '';
        /* eslint-disable */
        const innerJsCode =
          document.createTextNode(`!function(a,m,o,c,r,m){a[o+c]=a[o+c]||{setMeta:function(p){this.params=(this.params||[]).concat([p])}},a[o+r]=a[o+r]||function(f){a[o+r].f=(a[o+r].f||[]).concat([f])},a[o+r]({id:"734899",hash:"a29eda777dfd580a097dc4c1cf4ed6b2",locale:"en"})}(window,0,"amo_forms_","params","load");`)
        /* eslint-enable */
        script.appendChild(innerJsCode);
        document.getElementById('hubspotForm').appendChild(script);
        const script2 = document.createElement('script');
        script2.id = 'amoforms_script_734899';
        script2.setAttribute('async', 'async');
        script2.src = 'https://forms.amocrm.com/forms/assets/js/amoforms.js?1613469674';
        document.getElementById('hubspotForm').appendChild(script2);
      }
    });
  },
  methods: {
    scrollTo(element, to, duration, clear) {
      if (duration === 0) {
        return;
      }
      if (to < 0) to = 0;
      const difference = to - element.scrollY;
      const perTick = difference / duration * 10;
      if (clear) {
        clearTimeout(this.setTimeoutId);
      }
      this.setTimeoutId = setTimeout(() => {
        let newTop = element.scrollY + perTick;
        if (newTop < 0) newTop = 0;
        element.scrollTo({
          top: newTop,
        });
        if (newTop === 0) return false;
        if (element.scrollY === to) return;
        this.scrollTo(element, to, duration - 10);
      }, 10);
    },
    scrollToForm() {
      const anchorBlock = document.getElementById('hubspotFormContainer');
      this.scrollTo(window, anchorBlock.offsetTop, 500, 'clear');
    },
  },
};
</script>

<style scoped lang="scss">
  @import '../assets/sass/utils/variables';

  .hubspot-form {
    padding: 100px 0 50px;
    background: $color-white;
    position: relative;
    background: rgba($color-black, 0.8);

    @media (max-width: 767px) {
      padding: 50px 0 0;

      &_no-from {
        padding-bottom: 50px!important;
      }

    }

    &__title {
      margin-bottom: 50px;
      color: $color-white;

      @media (max-width: 767px) {
        margin-bottom: 20px;
      }

      span {
        display: block;
      }
    }

    &__sub-title {
      margin-bottom: 20px;
      color: $color-chartreuse;
    }

    &__content {
      max-width: 430px;
      margin: 0 auto;

      &_btn {
        text-align: center;
      }

    }

  }

  .btn {
    padding: 0 15px;
    height: 40px;
    background: $color-moody-blue;
    font-family: $font-global-medium;
    font-size: 16px;
    display: inline-block;
    color: $color-white;
    border: 1px solid transparent;
    width: auto;
    transition: opacity .2s ease-in, background .2s ease-in, color .2s ease-in;
    user-select: none;

    &:hover {
      border-color: transparent !important;
      background: $color-white !important;
      color: $color-moody-blue;
      opacity: 1 !important;
    }

  }

</style>

<style lang="scss">
  .test {
    background-color: red   ;
  }
</style>
