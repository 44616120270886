<template>
  <div class="extension">
    <div class="container">
      <div class="extension-box">
        <h2 class="extension__title">
          Powerful for organizations of any size
        </h2>
        <div class="description extension__desc">
          <span>
            While powering some of the largest ministries,
            our goal is to empower small organizations
          </span>
          <span>
            and provide them the necessary tools for steady growth and development.
          </span>
        </div>
        <div class="extension-content">
          <div class="extension-left">
            <div
              v-for="(item, index) in extensionItems"
              :key="index"
              class="extension-item"
            >
              <div class="extension-item__img">
                <img
                  :src="require('../assets/images/' + item.img + '.png')"
                >
              </div>
              <div class="extension-item__title">
                {{ item.title }}
              </div>
            </div>
          </div>
          <div class="extension-right">
            <div class="trees">
              <img
                src="../assets/images/trees.png"
                alt=""
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Extension',
  data() {
    return {
      extensionItems: [
        {
          img: 'extension__img-1',
          title: 'Multi-campus architecture',
        },
        {
          img: 'extension__img-2',
          title: 'Multi-website support',
        },
        {
          img: 'extension__img-3',
          title: 'Powerful user management',
        },
      ],
    };
  },
  methods: {
  },
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .extension {
    background: $color-white;
    padding-top: 80px;
    position: relative;
    overflow: hidden;

    &__desc {
      margin-top: 18px;
      position: relative;
      z-index: 15;
    }

    &-box {
      margin: 0 -15px;
    }

    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;
      }

      &__title {
        font-size: 18px;
      }

      &__img {
        margin-right: 16px;
        position: relative;
        top: 5px;
      }
    }

    &-content {
      display: flex;
      padding-bottom: 10px;
      position: relative;
      margin-bottom: 31px;
      margin-top: 28px;
    }

    &-left {
      align-self: flex-end;
      width: 25%;
      position: relative;
      top: -43px;
      left: 31px;
      z-index: 5;
    }

    &-right {
      width: 75%;
      position: relative;
      left: -73px;
      top: -17px;
    }

    &__title {
      text-align: left;
    }

    &__desc {
      text-align: left !important;

      span {
        display: block;
      }
    }
  }

  @media (max-width: 991px) {
    .extension {
      &-box {
        margin: 0;
      }
    }
  }

  @media (max-width: 767px) {
    .extension {
      padding-top: 39px;

      &-content {
        top: 0;
        padding-bottom: 250px;
      }

      &-item {
        &__title {
          font-size: 14px;
        }

        &__img {
          margin-right: 10px;
          top: 2px;
          max-width: 35px;
        }
      }

      &-left {
        width: 40%;
        position: absolute;
        left: 0;
        right: auto;
        top: 40px;
      }

      &-right {
        width: 60%;
        position: absolute;
        left: auto;
        right: 0;
        top: 0;
      }

      &__title {
        text-align: center;
      }
    }
  }

  @media (max-width: 480px) {
    .extension {

      &-content {
        top: 0;
        padding-bottom: 30%;
        margin-bottom: 21px;
      }

      &__desc {
        margin-top: 8px;
        text-align: center !important;
        margin-bottom: 25px;
      }

      &-right {
        position: absolute;
        right: auto;
        width: 100%;
        top: -15%;
        left: 51%;
        transform: translateX(-50%);
      }

      &-left {
        width: 100%;
        top: 0;
        left: 0;
        position: relative;
      }

      &-item {
        margin-bottom: 0;

        &__title {
          font-size: 9px;
          line-height: 15px;
        }

        &__img {
          margin-right: 9px;
          position: relative;
          top: 2px;
          max-width: 26px;
        }
      }
    }
  }
  @media (max-width: 375px) {
    .extension-right {
      top: -1%;
      left: 58%;
    }
  }
</style>
