<template>
  <div class="integrations">
    <div class="container">
      <h2 class="integrations__title">
        Integrations
      </h2>
      <div class="integrations__desc description">
        The only website platform that is integrated with:
      </div>
      <div class="integrations-list">
        <div class="integrations-item integrations-item_first">
          <div class="integrations-item__img integrations-item__img_api">
            <img
              src="../assets/images/logo-api.svg"
              alt=""
            >
          </div>
          <div class="integrations-item__img integrations-item__img_google">
            <img
              src="../assets/images/logo-ga.svg"
              alt=""
            >
          </div>
          <div class="integrations-item__img integrations-item__img_fb">
            <img
              src="../assets/images/logo-fbp.svg"
              alt=""
            >
          </div>
        </div>
        <div
          v-if="false"
          class="integrations-item integrations-item_second"
        >
          <div class="integrations-item__img integrations-item__img_rock">
            <img
              src="../assets/images/logo-rock.svg"
              alt=""
            >
          </div>
          <div class="integrations-item__img integrations-item__img_ccb">
            <img
              src="../assets/images/logo-ccb.svg"
              alt=""
            >
          </div>
          <div class="integrations-item__img integrations-item__img_acs">
            <img
              src="../assets/images/logo-acst.svg"
              alt=""
            >
          </div>
        </div>
        <div class="integrations-item integrations-item_third">
          <div class="integrations-item__img integrations-item__img_youtube">
            <img
              src="../assets/images/logo-youtube.svg"
              alt=""
            >
          </div>
          <div class="integrations-item__img integrations-item__img_vimeo">
            <img
              src="../assets/images/logo-vimeo.svg"
              alt=""
            >
          </div>
          <div class="integrations-item__img integrations-item__img_flurry">
            <img
              src="../assets/images/logo-flurry.svg"
              alt=""
            >
          </div>
        </div>
        <div class="integrations-item integrations-item_fourth">
          <div class="integrations-item__img integrations-item__img_platform">
            <img
              src="../assets/images/logo-if-mp.svg"
              alt=""
            >
          </div>
          <div class="integrations-item__img integrations-item__img_donor">
            <img
              src="../assets/images/logo-if-dm.svg"
              alt=""
            >
          </div>
          <div class="integrations-item__img integrations-item__img_forms">
            <img
              src="../assets/images/logo-if-form.svg"
              alt=""
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Integrations',
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .integrations {
    background: $color-tundora;
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative;
    overflow: hidden;
   &__title {
     color: $color-white;
   }

   &__desc {
     color: $color-white;
     margin-top: 9px;
   }

   &-list {
     display: flex;
     flex-wrap: wrap;
     padding-top: 80px;
     margin: 0 -40px;
     justify-content: center;
   }

   &-item {
     width: 25%;
     border-right: 1px solid $color-dusty-gray;
     display: flex;
     flex-direction: column;
     align-items: center;
     min-height: 308px;
     justify-content: center;

     &_first {
       width: 22%;
     }

     &_second {
       width: 25.5%;
     }

     &_third {
       width: 23.5%;
     }

     &:last-child {
       border-right: none;
       width: 21%;
     }

     &__img {
       margin-bottom: 50px;
       position: relative;
       filter: opacity(50%);
       transition: filter .2s ease-in;

       &:hover {
         filter: opacity(100%);
       }

       &_api {
         left: -15px;
         max-width: 130px;
       }

       &_platform {
         max-width: 190px;
         left: 32px;
         top: -2px;
       }

       &_donor {
         max-width: 220px;
         left: 48px;
         top: 4px;
       }

       &_forms {
         max-width: 120px;
         top: 13px;
       }

       &_youtube {
         max-width: 120px;
         top: -22px;
         left: -2px;
       }

       &_vimeo {
         top: -11px;
         left: -3px;
       }

       &_flurry {
         top: 26px;
         left: -3px;
       }

       &_rock {
         top: -17px;
       }

       &_ccb {
         top: -4px;
       }

       &_acs {
         top: 16px;
       }

       &_google {
         max-width: 120px;
         left: -10px;
         top: -6px;
       }

       &_fb {
         max-width: 145px;
         left: -9px;
         top: -6px;
       }

       &:last-child {
         margin-bottom: 0;
       }
     }
   }
 }

  @media (max-width: 991px) {
    .integrations {
      &-item {
        &__img {
          /*max-width: 100px;*/
        }
      }

      &-list {
        margin: 0 -10px;
      }
    }
  }

  @media (max-width: 767px) {
    .integrations {
      &-list {
        flex-direction: column;
        margin: 0;
      }

      &-item {
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        min-height: auto;
        border-bottom: 1px solid $color-dusty-gray;
        border-right: none;
        padding-bottom: 30px;
        padding-top: 30px;

        &:last-child {
          width: 100%;
          border-bottom: none;
        }

        &__img {
          left: 0;
          top: 0;
          right: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  @media (max-width: 580px) {
    .integrations {
      padding-top: 43px;
      padding-bottom: 13px;

      &__desc {
        margin-top: 19px;
        margin-bottom: 13px;
      }

      &-item {
        justify-content: center;
        padding-bottom: 10px;
        padding-top: 10px;
        margin-bottom: 4px;

        &__img {
          max-width: 60px;
          margin: 0 23px;

          &__gogole {
            max-width: 55px;
          }

          &_fb {
            max-width: 75px;
          }

          &_rock {
            max-width: 65px;
          }

          &_ccb {
            max-width: 90px;
          }

          &_acs {
            max-width: 75px;
          }

          &_flurry {
            max-width: 80px;
          }

          &_platform {
            max-width: 90px;
          }

          &_donor {
            max-width: 110px;
          }
        }
      }

      &-list {
        padding-top: 0;
      }
    }
  }
</style>
