<template>
  <div class="design-site">
    <div class="container">
      <div class="design-site-content">
        <h2 class="design-site__title">
          <span>Design</span>
        </h2>
        <div class="design-site__desc description">
          <span>Not all ministries are the same.</span>Your ministry is unique. Your website should be too.
        </div>
        <div class="design-site-tabs-bar">
          <div
            id="gallery-top"
            class="tabs__wrapper tabs__wrapper--overflow"
          >
            <swiper
              id="design-site-tabs"
              ref="swiperThumbs"
              :options="swiperOptionThumbs"
              class="design-site-tabs"
            >
              <swiper-slide
                v-for="(item, index) in designSiteTabs"
                :key="index"
                class="design-site-tabs__item swiper-lazy"
                :class="{'design-site-tabs__item_active': activeSlideThumbs === index}"
              >
                {{ item.tabName }}
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div
          id="gallery-thumbs"
          class="design-site-box wow fadeIn"
          data-wow-duration="2s"
        >
          <swiper
            ref="swiperTop"
            :options="swiperOptionTop"
          >
            <swiper-slide
              v-for="(item) in designSiteTabsContent"
              :key="item.id"
              class="design-site-box__slider"
            >
              <img
                class="design-site-box-item__img"
                :src="require('../assets/images/' + item.img)"
                alt=""
              >
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  name: 'DesignSite',
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      windowOldScrollPosition: 0,
      showContentImgScrollDown: true,
      showContentImgScrollUp: true,
      activeSlideThumbs: 0,
      sliderPageIndex: 1,
      swiperOptionTop: {
        slidesPerView: 1,
        loopedSlides: 5,
        longSwipesMs: 0,
        allowTouchMove: false,
        longSwipesRatio: 0,
        // autoplay: true,
        delay: 5000,
        effect: 'fade',
        speed: 1150,
        on: {
          slideChange: this.slideChangeSwiper,
        },
      },
      swiperOptionThumbs: {
        slidesPerView: 8,
        touchRatio: 0.2,
        loopedSlides: 5,
        slideToClickedSlide: false,
        on: {
          click: this.clickSwiper,
        },
        breakpoints: {
          991: {
            slidesPerView: 3,
          },
          600: {
            slidesPerView: 2,
          },
          480: {
            slidesPerView: 3,
          },
          450: {
            slidesPerView: 2,
          },
        },
      },
      activeItemId: 0,
      designSiteTabs: [
        {
          tabName: 'Latest designs',
        },
        {
          tabName: 'Mobile Optimized',
        },
        {
          tabName: 'Customize Anything',
        },
        {
          tabName: 'Homepage Layouts',
        },
      ],
      designSiteTabsContent: [
        {
          id: '1',
          img: 'design-slide1.jpg',
        },
        {
          id: '2',
          img: 'responsiveness-slide2.jpg',
        },
        {
          id: '3',
          img: 'constructor-slide3.jpg',
        },
        {
          id: '4',
          img: 'web-slide4.jpg',
        },
      ],
    };
  },
  beforeMount() {
    window.addEventListener('scroll', this.setWindowScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.setWindowScroll);
  },
  methods: {
    setWindowScroll() {
      this.$nextTick(() => {
        // let slideNumberTwo = document.getElementById('slideNumberTwo')
        const galleryTop = document.getElementById('gallery-top');
        const galleryThumbs = document.getElementById('gallery-thumbs');
        let scrollDirection;
        if (this.windowOldScrollPosition <= window.scrollY) {
          scrollDirection = 'down';
        } else {
          scrollDirection = 'up';
        }
        if (scrollDirection === 'down') {
          if ((window.innerHeight >= galleryTop.getBoundingClientRect().y)) {
            if (this.showContentImgScrollDown) {
              if (this.$refs.swiperThumbs !== undefined && this.$refs.swiperTop) {
                this.activeSlideThumbs = 0;
                this.sliderPageIndex = 1;
                this.$refs.swiperThumbs.swiper.slideTo(this.activeSlideThumbs, 0);
                this.$refs.swiperTop.swiper.slideTo(this.activeSlideThumbs, 0);
                this.$refs.swiperTop.swiper.autoplay.start();
                this.showContentImgScrollDown = false;
              }
            }
          } else if (!this.showContentImgScrollDown) {
            this.showContentImgScrollDown = true;
          }
        } else if ((galleryThumbs.getBoundingClientRect().bottom > 0)) {
          if (this.showContentImgScrollUp) {
            if (this.$refs.swiperThumbs !== undefined && this.$refs.swiperTop) {
              this.activeSlideThumbs = 0;
              this.sliderPageIndex = 1;
              this.$refs.swiperThumbs.swiper.slideTo(this.activeSlideThumbs, 0);
              this.$refs.swiperTop.swiper.slideTo(this.activeSlideThumbs, 0);
              this.$refs.swiperTop.swiper.autoplay.start();
              this.showContentImgScrollUp = false;
            }
          }
        } else if (!this.showContentImgScrollUp) {
          this.showContentImgScrollUp = true;
        }
        this.windowOldScrollPosition = window.scrollY;
      });
    },
    slideChangeSwiper() {
      this.activeSlideThumbs = this.$refs.swiperTop.swiper.activeIndex;
      if (this.activeSlideThumbs >= this.$refs.swiperThumbs.swiper.params.slidesPerView * this.sliderPageIndex) {
        this.$refs.swiperThumbs.swiper.slideTo(this.activeSlideThumbs);
        this.sliderPageIndex++;
      } else if (this.activeSlideThumbs === 0) {
        this.$refs.swiperThumbs.swiper.slideTo(this.activeSlideThumbs);
        this.sliderPageIndex = 1;
      }
      const sliderImg = document.getElementsByClassName('design-site-box-item__img');
      for (let i = 0; i < sliderImg.length; i++) {
        let srcContentImg = sliderImg[i].getAttribute('src').split('?')[0];
        srcContentImg += `?${Math.random()}`;
        sliderImg[i].setAttribute('src', srcContentImg);
      }
    },
    clickSwiper() {
      this.activeSlideThumbs = this.$refs.swiperThumbs.swiper.clickedIndex;
      this.$refs.swiperTop.swiper.slideTo(this.activeSlideThumbs);
      if (this.activeSlideThumbs > this.$refs.swiperThumbs.swiper.params.slidesPerView) {
        const indexSlidePage = Math.floor(this.activeSlideThumbs / this.$refs.swiperThumbs.swiper.params.slidesPerView);
        this.sliderPageIndex = indexSlidePage;
      }
      // this.activeIndexSlidePhoto = this.activeSlideThumbs
    },
  },
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .design-site {
    padding-bottom: 69px;
    background: $color-white;
    position: relative;
    overflow: hidden;

    &-content {
      margin: 0 -15px;
    }

    &__title {
      margin-bottom: 9px;

      span {
        display: block;
      }
    }

    &__desc {
      margin-bottom: 61px;

      span {
        display: block;
      }
    }

    .ps-container {
      max-width: 100%;
      width: 100%;
      margin: auto;
      margin-bottom: 40px;
    }

    .ps.ps--active-x>.ps__scrollbar-x-rail {
      display: block !important;
      opacity: 1 !important;
      height: 1px;
      background: $color-alto;
    }

    .ps>.ps__scrollbar-x-rail>.ps__scrollbar-x {
      background: transparent;
    }

    .ps__scrollbar-x {
      bottom: 0!important;
      height: 1px!important;
    }

    .ps.ps--active-y>.ps__scrollbar-y-rail {
      display: none !important;
    }

    &-tabs {
      display: flex;
      height: 100%;
      width: 100%;
      position: absolute;
      padding: 0;
      top: 0;
      align-items: center;
      list-style: none;
      will-change: transform;
      transition: transform .6s cubic-bezier(.86, 0, .07, 1);

      &__item {
        height: 100%;
        flex: 1;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 50px 14px;
        color: $color-alto;
        font-family: $font-global, sans-serif;
        font-size: 21px;
        white-space: nowrap;
        text-align: center;
        cursor: pointer;
        transition: color .3s ease;
        user-select: none;

        &:hover {
          color: $color-moody-blue;
        }

        &:before {
          content: "";
          position: absolute;
          top: auto;
          bottom: 0;
          left: 0;
          /*right: 0;*/
          z-index: 3;
          /*width: 100%;*/
          width: 0;
          height: 1px;
          background-color: transparent;
          border-radius: 1px;
          transition: all .3s ease-in;
        }

        &_active {
          color: $color-moody-blue;

          &:before {
            background-color: $color-moody-blue !important;
            left: 0;
            width: 100%;
          }
        }
      }

      &-bar {
        width: 100%;
        margin-bottom: 14px;
        position: relative;
        height: 42px;
        z-index: 1;
        border-bottom: 1px solid $color-alto;

        .tabs__wrapper {
          position: relative;
          overflow: visible;
          height: inherit;
        }
      }
    }

    &-box {
      position: relative;
      max-width: 1140px;
      width: 100%;
      margin: auto;
      margin-bottom: 32px;
      height: 761px;

      &__slider {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 761px;
        background: $color-white;
      }

      &-item {
        background: $color-mercury;
        display: flex;
        justify-content: center;
        max-width: 1140px;

        &__title {
          line-height: 71px;
          font-size: 68px;
          font-family: $font-global-bold, sans-serif;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .design-site {
      &-list {
        &__links {
          font-size: 20px;

          &:before {
            right: 10px;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .design-site {
      &-list {
        margin: 0;
        &__links {
          font-size: 18px;
          margin: 0 5px;
        }
      }

      &-box {
        height: 300px;

        &__slider {
          height: 300px;

          img {
            /*height: 300px;*/
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .design-site {
      padding-bottom: 20px;

      &-content {
        margin: 0;
      }

      &__desc {
        margin-bottom: 28px;
      }

      &-tabs {
        &__item {
          font-size: 16px;
          padding-bottom: 13px;
        }

        &-bar {
          height: 32px;
          margin-bottom: 8px;
        }
      }

      &-box {
        height: 180px;

        &__slider {
          height: 180px;

          img {
            /*height: 180px;*/
          }
        }
      }
    }
  }
</style>
