<template>
  <div class="feedback">
    <div class="container">
      <h2 class="feedback__title">
        Feedback
      </h2>
      <div class="feedback__description description">
        Positive feedback about solutions that our team created.
      </div>
      <div class="feedback-content">
        <div class="feedback-row">
          <div class="feedback-row-item feedback-row-item_green">
            <div class="feedback-row-item__text">
              <span>“A dream come true for most</span>
              <span>small churches & ministries”</span>
            </div>
            <div class="feedback-row-item__author">
              - Jason Caston, iChurch Method
            </div>
          </div>
          <div class="feedback-row-item feedback-row-item_purple">
            <div class="feedback-row-item__text">
              <span>“A must-have for churches of the future”</span>
            </div>
            <div class="feedback-row-item__author">
              - Derek Smith
            </div>
          </div>
          <div class="feedback-row-item feedback-row-item_right">
            <div class="feedback-row-item__text">
              <span>“Incredibly robust yet simple”</span>
            </div>
            <div class="feedback-row-item__author">
              David Johnsson
            </div>
          </div>
        </div>
        <div class="feedback-row">
          <div class="feedback-row-item feedback-row-item_left">
            <div class="feedback-row-item__text">
              <span>“Hello! This thing is crazy!!!”</span>
            </div>
            <div class="feedback-row-item__author">
              - Preston Morrison, Gateway Church
            </div>
          </div>
          <div class="feedback-row-item feedback-row-item_blue">
            <div class="feedback-row-item__text">
              <span>“A whole new level, a new horizon.</span>
              <span>Thank you for helping Life Without Limbs</span>
              <span>reach millions of people around the world”.</span>
            </div>
            <div class="feedback-row-item__author">
              - Nick Vujicic, Life Without Limbs
            </div>
          </div>
          <div class="feedback-row-item feedback-row-item_yellow">
            <div class="feedback-row-item__text">
              <!--              <span>“A no-brainer, so ahead of the curve!”</span>-->
              <span>“Search no more. It’s the best platform out there!”</span>
            </div>
            <div class="feedback-row-item__author">
              - Shawn Brann, Ignite Europe
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Feedback',
  data() {
    return {};
  },
  mounted() {
    this.$nextTick(() => {
      const wow = new window.WOW(
        {
          boxClass: 'wow',
          animateClass: 'animated',
          offset: 0,
          mobile: true,
          live: false,
          scrollContainer: null,
        },
      );
      wow.init();
    });
  },
  methods: {},
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .feedback {
    padding-top: 80px;
    padding-bottom: 150px;
    background: $color-black-squeeze;
    position: relative;
    overflow: hidden;

    &__description {
      margin-top: 9px;
    }

    &-content {
      margin-top: 53px;
      position: relative;
    }

    &-row {
      display: flex;

      &-item {
        display: flex;
        height: 158px;
        flex-direction: column;
        justify-content: center;
        position: relative;
        flex-shrink: 0;

        &__text {
          line-height: 34px;
          position: relative;

          span {
            display: block;
          }
        }

        &__author {
          font-size: 14px;
          line-height: 18px;
          position: relative;
        }

        &_green {
          background: rgba(68, 164, 95, .2);
          border-radius: 98px 0 0 98px;
          max-width: 451px;
          width: 100%;
          top: 130px;
          left: -15px;

          .feedback-row-item__text {
            margin-left: 80px;
            top: -3px;
          }

          .feedback-row-item__author {
            text-align: center;
            left: 53px;
            top: 4px;
          }
        }

        &_purple {
          border-radius: 0 98px 98px 0;
          background: rgba(113, 111, 206, .2);
          left: -156px;
          max-width: 360px;
          width: 100%;

          .feedback-row-item__text {
            margin-left: 32px;
            top: -3px;
          }

          .feedback-row-item__author {
            text-align: center;
            left: 46px;
            top: 4px;
          }
        }

        &_right {
          max-width: 305px;
          width: 100%;
          min-height: 205px;
          border-top: 2px solid $color-san-juan;
          border-left: 2px solid $color-san-juan;
          border-radius: 98px 0 0 0;
          top: 6px;
          left: -25px;

          .feedback-row-item__text {
            margin-left: 55px;
            top: -10px;
          }

          .feedback-row-item__author {
            text-align: right;
            top: -3px;
            left: 5px;
          }
        }

        &_left {
          max-width: 305px;
          width: 100%;
          min-height: 158px;
          border-right: 2px solid $color-san-juan;
          border-bottom: 2px solid $color-san-juan;
          border-radius: 0 0 98px 0;
          left: 40px;
          top: 119px;

          .feedback-row-item__text {
            top: -22px;
          }

          .feedback-row-item__author {
            text-align: right;
            left: -65px;
            top: -15px;
          }
        }

        &_blue {
          max-width: 748px;
          width: 100%;
          min-height: 239px;
          background: rgba(71, 196, 224, .2);
          border-radius: 0 119px 0 119px;
          left: 70px;
          top: -18px;

          .feedback-row-item__text {
            margin-left: 99px;
            top: -13px;
          }

          .feedback-row-item__author {
            margin-left: 103px;
            top: -1px;
          }
        }

        &_yellow {
          max-width: 222px;
          width: 100%;
          background: rgba(252, 235, 59, .7);
          border-radius: 79px;
          height: 158px;
          position: absolute;
          bottom: -70px;
          right: 86px;

          .feedback-row-item__text {
            text-align: center;
            top: -3px;

            span {
              display: block;
              font-size: 20px;
            }
          }

          .feedback-row-item__author {
            text-align: right;
            left: -20px;
            top: 4px;
          }
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .feedback {
      &-row {
        &-item {

          &__text {
            font-size: 16px;
            line-height: 24px;
          }

          &_purple {
            left: -218px;

            .feedback-row-item__text {
              margin-left: 30px;
            }

            .feedback-row-item__author {
              left: 25px;
            }
          }

          &_blue {
            max-width: 580px;
          }

          &_yellow {
            right: 0;
            padding: 20px;

            .feedback-row-item__author {
              left: 0;
            }
          }

          &_green {
            left: 80px;
            top: 135px;

            .feedback-row-item__text {
              margin-left: 30px;
              top: 0;
            }

            .feedback-row-item__author {
              left: 30px;
              top: 5px;
            }
          }

          &_right {
            left: -200px;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .feedback {
      &-row {
        &-item {
          &_green {
            top: 153px;
            width: 283px;
            left: 16px;
          }

          &_purple {
            max-width: 371px;
            width: 315px;
            left: -276px;
          }

          &_right {
            left: -256px;
          }

          &_left {
            left: 12px;
          }

          &_blue {
            left: -66px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .feedback {
      height: 873px;
      padding-top: 40px;

      .container {
        padding: 0;
      }

      &__description {
        padding: 0 15px;
        margin-top: 16px;
      }

      &-content {
        width: 375px;
        margin: 22px auto;
      }

      &-row {
        &-item {
          &_green {
            top: 111px;
            left: 132px;
            width: 242px;
            border-radius: 90px 0 0 98px;
            max-width: 435px;
            height: 147px;

            .feedback-row-item__text {
              margin: 0 15px 15px 36px;
              top: 0;
              font-size: 16px;
              line-height: 25px;
            }

            .feedback-row-item__author {
              left: 5px;
              top: -2px;
              margin: 0 15px;
            }
          }

          &_purple {
            left: -247px;
            top: 193px;
            height: 144px;
            width: 241px;

            .feedback-row-item__text {
              margin-left: 19px;
              padding: 0 15px;
              box-sizing: border-box;
              top: -7px;
            }

            .feedback-row-item__author {
              left: 5px;
            }
          }

          &_right {
            top: 1px;
            left: -455px;
            border-radius: 83px 0 0 0;
            max-width: 225px;
            width: 100%;
            min-height: 147px;
            height: 147px;

            .feedback-row-item__text {
              margin-left: 62px;
              top: -8px;
            }

            .feedback-row-item__author {
              top: 0;
            }

            .feedback-row-item__item {
              top: -16px;
              left: -42px;
            }
          }

          &_left {
            top: 417px;
            left: 66px;
            min-height: auto;
            border-radius: 0 0 90px 0;
            height: 129px;
            padding: 4px;
            box-sizing: border-box;
            width: 245px;
            max-width: 100%;

            .feedback-row-item__text {
              top: -19px;
              left: 7px;
              padding: 0 15px;
            }

            .feedback-row-item__author {
              top: 7px;
              left: -35px;
            }
          }

          &_blue {
            border-radius: 0 77px 0 78px;
            width: 334px;
            left: -219px;
            top: 243px;
            height: 166px;
            min-height: 184px;

            .feedback-row-item__text {
              padding: 0 15px;
              box-sizing: border-box;
              margin-left: 22px;
              top: -3px;
            }

            .feedback-row-item__author {
              top: 12px;
            }
          }

          &_yellow {
            max-width: 250px;
            height: 133px;
            bottom: -99px;
            right: 11px;

            .feedback-row-item__text {
              top: -5px;
              padding: 0 15px;
              box-sizing: border-box;

              span {
                font-size: 16px;
              }

            }

            .feedback-row-item__author {
              top: 10px;
              left: -15px !important;
            }
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
  }

  @media (max-width: 414px) {
    .feedback {
      height: 1016px;

      &-row {
        &-item {
          &_green {
            left: 78px;
            top: 140px;
          }

          &_purple{
            top: 265px;
          }

          &_left{
            top: 558px;
          }

          &_yellow {
            right: 57px;
            bottom: -186px;
          }

          &_blue {
            left: -259px;
            top: 357px;
          }
        }
      }

      .feedback-row-item_left {
        .feedback-row-item__author {
          top: -7px;
        }
      }

    }
  }
</style>
