<template>
  <div
    class="price-comparison-overlay"
    @click.prevent="$emit('update:showModalPriceComparison', false)"
  >
    <div class="price-comparison-bg">
      <VuePerfectScrollbar
        class="price-comparison"
      >
        <div @click.stop>
          <div
            class="price-comparison__close"
            @click.prevent="$emit('update:showModalPriceComparison', false)"
          >
            <i class="icon-delete"></i>
          </div>
          <h2 class="price-comparison__title">
            See how much you could save with ImpactFactors Giving platform
          </h2>
          <div class="price-comparison__info">
            On average organizations are saving up to <span>40%</span> on cost per year when upgrading
            their giving platform to ImpactFactors
            <div>Better giving platform + better processor = big savings to re-invest in your ministry!*</div>
          </div>
          <div
            class="platforms"
            :class="{'platforms_hidden': platformsHidden}"
          >
            <div
              v-if="platformsHidden"
              class="platforms_hidden-close"
              @click.prevent="platformsHidden = !platformsHidden"
            >
              <i class="icon-delete"></i>
            </div>
            <div class="platforms-content">
              <transition
                name="fade-block"
                mode="out-in"
              >
                <div
                  v-if="!platformsHidden"
                  class="platforms-left"
                >
                  <div class="platforms-left__content">
                    <transition
                      :enterActiveClass="'animate-table zoomIn'"
                      :leaveActiveClass="'animate-table zoomOut'"
                      appear
                      mode="out-in"
                    >
                      <div
                        v-if="showPushPay"
                        class="push-pay"
                      >
                        <div class="push-pay__desc">
                          Please enter your current monthly fee with PushPay
                        </div>
                        <div class="push-pay-item">
                          <label>
                            <span>Monthly Fee</span>
                            <input
                              v-model="monthlyFeeComparison"
                              type="text"
                              name="monthlyFeeComparison"
                              :class="{'error': validator.errors.has('monthlyFeeComparison')}"
                            >
                          </label>
                        </div>
                        <button
                          class="push-pay__save"
                          @click.prevent="submitMonthlyFee()"
                        >
                          OK
                        </button>
                      </div>
                    </transition>

                    <h3 class="platforms__title">
                      Giving platform
                    </h3>
                    <form class="platforms-list">
                      <div
                        v-for="(item, index) in radioCur"
                        v-if="((index % 2 === 0) || (index === 9))"
                        :key="index"
                        class="platforms-list__item"
                      >
                        <label
                          class="radio-item"
                          :class="{'radio-item_small': item.name === 'Other'}"
                          @click="platformHandler(item)"
                        >
                          <input
                            v-model="platformsList"
                            type="radio"
                            checked="checked"
                            :value="index"
                          >
                          <span class="radio-item__label">{{ item.name }}</span>
                          <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="platforms-list__item">
                        <input
                          type="submit"
                          class="platforms__btn"
                          value="Set your rates manually"
                          :class="{'disabled': otherPlatform()}"
                          @click.prevent="platformsHidden = !platformsHidden"
                        >
                      </div>
                    </form>
                  </div>
                </div>
              </transition>
              <transition
                name="fade-block"
                mode="out-in"
              >
                <div
                  v-if="!platformsHidden"
                  class="platforms-right"
                >
                  <h3 class="platforms__title">
                    Merchant provider
                  </h3>
                  <form class="platforms-list">
                    <div
                      v-for="(item, index) in radioCur"
                      v-if="((index % 2 !== 0) && (index !== 9))"
                      :key="index"
                      class="platforms-list__item"
                    >
                      <label
                        class="radio-item"
                        :class="{'radio-item_small': item.name === 'Other '}"
                      >
                        {{ item.name }}
                        <input
                          v-model="platformsList"
                          type="radio"
                          checked="checked"
                          :value="index"
                        >
                        <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="platforms-list__item">
                      <input
                        type="submit"
                        class="platforms__btn"
                        value="Set your rates manually"
                        :class="{'disabled': otherPlatformR()}"
                        @click.prevent="platformsHidden = !platformsHidden"
                      >
                    </div>
                  </form>
                </div>
              </transition>
              <transition
                name="fade-block"
                mode="out-in"
              >
                <div
                  v-if="platformsHidden"
                  class="platforms-hidden"
                >
                  <h3 class="platforms__title">
                    Set your rates manually
                  </h3>
                  <form class="platforms-listing">
                    <div class="platforms-item">
                      <label>
                        <span>Monthly Fee:</span>
                        <input
                          v-model="objOther.monthlyFee"
                          type="text"
                          autofocus
                          name="monthlyFee"
                          :class="{'error': validator.errors.has('monthlyFee')}"
                        >
                      </label>
                    </div>
                    <div class="platforms-item">
                      <label>
                        <span>Debit card fee:</span>
                        <input
                          v-model="objOther.debitCardFee"
                          type="text"
                          name="debitCardFee"
                          :class="{'error': validator.errors.has('debitCardFee')}"
                        >
                      </label>
                    </div>
                    <div class="platforms-item">
                      <label>
                        <span>ACH Fee:</span>
                        <input
                          v-model="objOther.achFee"
                          type="text"
                          name="achFee"
                          :class="{'error': validator.errors.has('achFee')}"
                        >
                      </label>
                    </div>
                    <div class="platforms-item">
                      <label>
                        <span>Transaction Fee:</span>
                        <input
                          v-model="objOther.transactionFee"
                          type="text"
                          name="transactionFee"
                          :class="{'error': validator.errors.has('transactionFee')}"
                        >
                      </label>
                    </div>
                    <div class="platforms-item">
                      <label>
                        <span>Credit card fee:</span>
                        <input
                          v-model="objOther.creditCardFee"
                          type="text"
                          name="creditCardFee"
                          :class="{'error': validator.errors.has('creditCardFee')}"
                        >
                      </label>
                    </div>
                    <div class="platforms-item">
                      <label>
                        <span>Per Check:</span>
                        <input
                          v-model="objOther.perCheck"
                          type="text"
                          name="perCheck"
                          :class="{'error': validator.errors.has('perCheck')}"
                        >
                      </label>
                    </div>
                    <input
                      type="submit"
                      class="platforms-listing__save"
                      value="Save"
                      @click.prevent="setRates()"
                    >
                  </form>
                </div>
              </transition>
              <div class="platforms-between">
                <span>
                  or
                </span>
              </div>
            </div>
          </div>
          <div class="transactions">
            <div class="transactions-item">
              <div class="transactions-item__slider">
                <vue-slider
                  ref="sliderFirst"
                  v-model="numberOfTransactionsSlider"
                  :tooltip="false"
                  :min="0"
                  :max="70000"
                >
                </vue-slider>
              </div>
              <input
                v-model="numberOfTransactions"
                class="transactions-item__input"
                type="text"
              >
              <div class="transactions-item__title">
                Average number of transactions per month?
              </div>
            </div>
            <div class="transactions-item">
              <div class="transactions-item__slider">
                <vue-slider
                  ref="sliderSecond"
                  v-model="monthlyProcessingSlider"
                  :tooltip="false"
                  :min="0"
                  :max="2000000"
                >
                </vue-slider>
              </div>
              <div class="transactions-item__block">
                <input
                  v-model="monthlyProcessing"
                  class="transactions-item__input"
                  type="text"
                >
              </div>
              <div class="transactions-item__title">
                Total average monthly processing?
              </div>
            </div>
          </div>
          <div class="savings">
            <div class="savings__help">
              <i
                class="icon-help-saving"
                @mouseenter="showSavingsTable = true"
                @mouseleave="showSavingsTable = false"
              ></i>
            </div>
            <transition
              :enterActiveClass="'animate-table zoomIn'"
              :leaveActiveClass="'animate-table zoomOut'"
              appear
              mode="out-in"
            >
              <table
                v-show="showSavingsTable"
                class="savings-table"
              >
                <tr>
                  <th>Our Giving Platform Fees:</th>
                  <th></th>
                </tr>
                <tr
                  v-for="item in dataTableSavings"
                  :key="item.id"
                >
                  <td>{{ item.text }}</td>
                  <td>{{ item.value }}</td>
                </tr>
              </table>
            </transition>
            <div class="savings__title">
              Your approximate savings:
            </div>
            <div class="savings-content">
              <div class="savings-left">
                <div class="savings__sum savings-left__sum">
                  $ {{ annualResult }}
                </div>
                <div class="savings__year savings-left__year">
                  /year
                </div>
              </div>
              <div class="savings-right">
                <div class="savings__sum savings-right__sum">
                  $ {{ fiveYearResult }}
                </div>
                <div class="savings__year savings-right__year">
                  /in 5 years
                </div>
              </div>
              <div class="savings-between">
                <span>
                  or
                </span>
              </div>
            </div>
            <!--            <div class="savings-between ">-->
            <!--              <span>-->
            <!--                or-->
            <!--              </span>-->
            <!--            </div>-->
          </div>
          <div class="price-comparison__desc">
            *Estimate for ImpactFactors Giving Platform when purchased with a mobile app, website
            or other ImpactFactors’ solutions. If ImpactFactors Giving Platform purchased
            separately without other solutions, then the average fee will be 2.9% per
            transaction. Please contact for more information. Comparisons based
            on the pricing published on respective providers’ website and/or other
            pages, such as
            <a
              href="https://get.tithe.ly/pushpay"
              target="_blank"
            >PushPay,</a>
            <a
              href="https://www.easytithe.com/pricing.htm"
              target="_blank"
            >EasyTithe,</a>
            <a
              href="https://www.vision2systems.com/pricing"
              target="_blank"
            >Vision2,</a>
            <a
              href="https://www.fellowshipone.com/solutions/online-giving"
              target="_blank"
            >FellowshipOne,</a>
            <a
              href="https://get.tithe.ly/pricing"
              target="_blank"
            >Tithely,</a>
            <a
              href="https://kindrid.com/pricing"
              target="_blank"
            >Kindrid,</a>
            <a
              href="https://paypal.com/webapps/mpp/paypal-fees"
              target="_blank"
            >PayPal,</a>
            <a
              href="https://www.paypal.com/us/webapps/mpp/bigcommerce/get-started/pricing-fees"
              target="_blank"
            >BrainTree,</a>
            <a
              href="https://stripe.com/us/pricing"
              target="_blank"
            >Stripe</a>
            . Please contact each provider to
            their complete pricing information. Pricing is subject to change
            without notice.
          </div>
        </div>
      </VuePerfectScrollbar>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import vueSlider from 'vue-slider-component';
import { Validator } from 'vee-validate';

export default {
  name: 'Index',
  components: {
    vueSlider,
    VuePerfectScrollbar,
  },
  props: ['showModalPriceComparison'],
  data() {
    return {
      pushPayValue: 0,
      showPushPay: false,
      monthlyFeeComparison: '',
      dataTableSavings: [
        {
          id: 0,
          text: 'Debit',
          value: '2.30%',
        },
        {
          id: 1,
          text: 'Credit',
          value: '2.30%',
        },
        {
          id: 2,
          text: 'Non Qualified',
          value: '2.30%',
        },
        {
          id: 3,
          text: 'Transaction fee',
          value: '0.30',
        },
        {
          id: 4,
          text: 'Monthly fee',
          value: '$0',
        },
        {
          id: 5,
          text: 'Set up Fee',
          value: '$0',
        },
        {
          id: 6,
          text: 'ACH',
          value: '1.00%',
        },
        {
          id: 7,
          text: 'Per Check',
          value: '$0.75',
        },
      ],
      showSavingsTable: false,
      validator: {},
      other: false,
      monthlyFeeImpact: 0,
      debitCardFeeImpact: 0,
      creditCardFeeImpact: 0,
      transactionFeeImpact: 0,
      achFeeImpact: 0,
      perCheckImpact: 0,
      monthlyFeeCur: 0,
      debitCardFeeCur: 0,
      creditCardFeeCur: 0,
      transactionFeeCur: 0,
      achFeeCur: 0,
      perCheckCur: 0,
      totalImpact: 0,
      totalCur: 0,
      impactRes: 0,
      chooseRes: 0,
      annualResDef: 0,
      fiveYearResDef: 0,
      numberOfTransactionsDef: 2000,
      monthlyProcessingDef: 300000,
      // numberOfTransactionsDef: 20000,
      // monthlyProcessingDef: 3000000,
      platformsHidden: false,
      platformsList: 4,
      objOther: {
        monthlyFee: '',
        debitCardFee: '',
        creditCardFee: '',
        transactionFee: '',
        achFee: '',
        perCheck: '',
      },
      radioCurDef: [
        {
          name: 'PushPay',
          monthlyFee: '99',
          debitCardFee: '2.9',
          creditCardFee: '2.9',
          transactionFee: '0.30',
          achFee: '2.9',
          perCheck: '0.75',
        },
        {
          name: 'authorize.net',
          monthlyFee: '25',
          debitCardFee: '2.9',
          creditCardFee: '2.9',
          transactionFee: '0.30',
          achFee: '0.75',
          perCheck: '0.75',
        },
        {
          name: 'FellowshipOne',
          monthlyFee: '0',
          debitCardFee: '3',
          creditCardFee: '3',
          transactionFee: '0.39',
          achFee: '0.75',
          perCheck: '0.39',
        },
        {
          name: 'PayPal',
          monthlyFee: '25',
          debitCardFee: '2.9',
          creditCardFee: '2.9',
          transactionFee: '0.30',
          achFee: '2.9',
          perCheck: '0.25',
        },
        {
          name: 'Vision2',
          monthlyFee: '0',
          debitCardFee: '3.9',
          creditCardFee: '3.9',
          transactionFee: '0.20',
          achFee: '1.9',
          perCheck: '0.50',
        },
        {
          name: 'Braintree',
          monthlyFee: '0',
          debitCardFee: '2.9',
          creditCardFee: '2.9',
          transactionFee: '0.30',
          achFee: '0.75',
          perCheck: '0.75',
        },
        {
          name: 'EasyTithe',
          monthlyFee: '10',
          debitCardFee: '3',
          creditCardFee: '3',
          transactionFee: '0.39',
          achFee: '1',
          perCheck: '0.39',
        },
        {
          name: 'Stripe',
          monthlyFee: '0',
          debitCardFee: '2.9',
          creditCardFee: '2.9',
          transactionFee: '0.30',
          achFee: '0.8',
          perCheck: '0.75',
        },
        {
          name: 'tithe.ly',
          monthlyFee: '0',
          debitCardFee: '2.9',
          creditCardFee: '2.9',
          transactionFee: '0.30',
          achFee: '1',
          perCheck: '0.30',
        },
        {
          name: 'Kindrid',
          monthlyFee: '45',
          debitCardFee: '2.6',
          creditCardFee: '2.6',
          transactionFee: '0.30',
          achFee: '0.75',
          perCheck: '0.30',
        },
        {
          name: 'Other',
          monthlyFee: '',
          debitCardFee: '',
          achFee: '',
          transactionFee: '',
          creditCardFee: '',
          perCheck: '',
        },
        {
          name: 'Other ',
          monthlyFee: '',
          debitCardFee: '',
          achFee: '',
          transactionFee: '',
          creditCardFee: '',
          perCheck: '',
        },
      ],
      radioDef: [
        {
          name: 'ImpactFactors',
          monthlyFee: '0',
          debitCardFee: '2.3',
          creditCardFee: '2.3',
          transactionFee: '0.30',
          achFee: '1',
          perCheck: '0.75',
        },
      ],
    };
  },
  computed: {
    radioCur: {
      get() {
        return this.radioCurDef;
      },
      set(data) {
        this.radioCur = data;
      },
    },
    annualRes: {
      get() {
        return this.annualResDef;
      },
      set(data) {
        this.annualResDef = data;
      },
    },
    fiveYearRes: {
      get() {
        return this.fiveYearResDef;
      },
      set(data) {
        this.fiveYearResDef = data;
      },
    },
    rates: {
      get() {
        return this.ratesDef;
      },
      set(data) {
        this.ratesDef = data;
      },
    },
    numberOfTransactionsSlider: {
      get() {
        return this.numberOfTransactionsDef;
      },
      set(data) {
        this.numberOfTransactionsDef = data;
      },
    },
    monthlyProcessingSlider: {
      get() {
        return this.monthlyProcessingDef;
      },
      set(data) {
        this.monthlyProcessingDef = data;
      },
    },
    numberOfTransactions: {
      get() {
        return this.convertNumber(this.numberOfTransactionsDef);
      },
      set(data) {
        const arr = data.split(',');
        const newArr = arr.join('');
        const newInt = parseInt(newArr);
        let number = newInt;
        if (isNaN(number)) number = 0;
        this.numberOfTransactionsDef = number;
      },
    },
    monthlyProcessing: {
      get() {
        return this.convertNumber(this.monthlyProcessingDef);
      },
      set(data) {
        const arr2 = data.split(',');
        const newArr2 = arr2.join('');
        const newInt2 = parseInt(newArr2);
        let number = newInt2;
        if (isNaN(number)) number = 0;
        this.monthlyProcessingDef = number;
      },
    },
    /*eslint-disable */
    annualResult: function () {
      this.totalImpactMet()
      this.totalCurMet()
      if (isNaN(this.totalCur)) return 0
      this.annualRes = this.totalCur - this.totalImpact
      /* eslint-enable */
      const res = this.convertNumber(parseFloat((this.annualRes + this.pushPayValue) * 12).toFixed());
      return res;
    },
    fiveYearResult() {
      this.totalCurMet();
      this.totalImpactMet();
      if (isNaN(this.totalCur)) return 0;
      /* eslint-disable */
      this.fiveYearRes = this.totalCur - this.totalImpact
      /* eslint-enable */
      const res = this.convertNumber(parseFloat((this.fiveYearRes + this.pushPayValue) * 60).toFixed());
      return res;
    },
    transactions() {
      return parseFloat(this.radioCur[this.platformsList].transactionFee) * parseFloat(this.numberOfTransactions);
    },
    monthly() {
      return parseFloat(this.radioCur[this.platformsList].monthlyFee) * parseFloat(this.monthlyProcessing);
    },
  },
  mounted() {
    // this.$nextTick(() => {
    //   setTimeout(() => {
    //     this.$refs.sliderFirst.refresh()
    //     this.$refs.sliderSecond.refresh()
    //   }, 2000)
    // })
  },
  beforeMount() {
    this.validator = Validator.create();
    this.validator.attach({ name: 'monthlyFee', rules: { required: true } });
    this.validator.attach({ name: 'debitCardFee', rules: { required: true } });
    this.validator.attach({ name: 'achFee', rules: { required: true } });
    this.validator.attach({ name: 'transactionFee', rules: { required: true } });
    this.validator.attach({ name: 'creditCardFee', rules: { required: true } });
    this.validator.attach({ name: 'perCheck', rules: { required: true } });
    this.validator.attach({ name: 'monthlyFeeComparison', rules: { required: true } });
    // this.$nextTick(() => {
    setTimeout(() => {
      this.$refs.sliderFirst.refresh();
      this.$refs.sliderSecond.refresh();
    }, 1000);
    // })
    const to = this.$route;
    const nearestWithMeta = to.meta.metaTags2;
    nearestWithMeta.map((tagDef) => {
      const tag = document.createElement('meta');
      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });
      tag.setAttribute('data-vue-router-controlled', '');
      return tag;
    })
      .forEach((tag) => document.head.appendChild(tag));
  },
  beforeDestroy() {
    const to = this.$route;
    const nearestWithMeta = to.meta.metaTags;
    nearestWithMeta.map((tagDef) => {
      const tag = document.createElement('meta');
      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });
      tag.setAttribute('data-vue-router-controlled', '');
      return tag;
    })
      .forEach((tag) => document.head.appendChild(tag));
  },
  methods: {
    platformHandler(item) {
      if (item.name === 'PushPay') {
        this.showPushPay = true;
      } else {
        this.showPushPay = false;
      }
    },
    submitMonthlyFee() {
      this.validator.validateAll({
        monthlyFeeComparison: this.monthlyFeeComparison,
      }).then((result) => {
        if (result) {
          this.pushPayValue = parseInt(this.monthlyFeeComparison);
          this.showPushPay = false;
          // console.log(result)
        }
      });
    },
    setRates() {
      this.validator.validateAll({
        monthlyFee: this.objOther.monthlyFee,
        debitCardFee: this.objOther.debitCardFee,
        achFee: this.objOther.achFee,
        transactionFee: this.objOther.transactionFee,
        creditCardFee: this.objOther.creditCardFee,
        perCheck: this.objOther.perCheck,
      }).then((result) => {
        if (result) {
          // console.log(this.radioCur[this.platformsList])
          this.radioCur[this.platformsList].monthlyFee = this.objOther.monthlyFee;
          this.radioCur[this.platformsList].debitCardFee = this.objOther.debitCardFee;
          this.radioCur[this.platformsList].achFee = this.objOther.achFee;
          this.radioCur[this.platformsList].transactionFee = this.objOther.transactionFee;
          this.radioCur[this.platformsList].creditCardFee = this.objOther.creditCardFee;
          this.radioCur[this.platformsList].perCheck = this.objOther.perCheck;
          this.objOther.monthlyFee = '';
          this.objOther.debitCardFee = '';
          this.objOther.achFee = '';
          this.objOther.transactionFee = '';
          this.objOther.creditCardFee = '';
          this.objOther.perCheck = '';
          this.platformsHidden = !this.platformsHidden;
          this.validator.reset();
        }
      });
    },
    convertNumber(number) {
      return String(number).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },
    impact() {
      this.debitCardFeeImpact = (parseFloat(this.radioDef[0].debitCardFee) / 100) * (parseFloat(this.monthlyProcessingDef) * 60 / 100);
      this.creditCardFeeImpact = (parseFloat(this.radioDef[0].creditCardFee) / 100) * (parseFloat(this.monthlyProcessingDef) * 20 / 100);
      this.transactionFeeImpact = parseFloat(this.radioDef[0].transactionFee) * (parseFloat(this.numberOfTransactionsDef) * 80 / 100);
      this.achFeeImpact = parseFloat(this.radioDef[0].achFee / 100) * (parseFloat(this.monthlyProcessingDef) * 20 / 100);
      this.perCheckImpact = parseFloat(this.radioDef[0].perCheck) * (parseFloat(this.numberOfTransactionsDef) * 20 / 100);
    },
    choose() {
      this.debitCardFeeCur = parseFloat(this.radioCur[this.platformsList].debitCardFee / 100) * (parseFloat(this.monthlyProcessingDef) * 60 / 100);
      this.creditCardFeeCur = parseFloat(this.radioCur[this.platformsList].creditCardFee / 100) * (parseFloat(this.monthlyProcessingDef) * 20 / 100);
      this.transactionFeeCur = parseFloat(this.radioCur[this.platformsList].transactionFee) * (parseFloat(this.numberOfTransactionsDef) * 80 / 100);
      this.achFeeCur = parseFloat(this.radioCur[this.platformsList].achFee / 100) * (parseFloat(this.monthlyProcessingDef) * 20 / 100);
      this.perCheckCur = parseFloat(this.radioCur[this.platformsList].perCheck) * (parseFloat(this.numberOfTransactionsDef) * 20 / 100);
    },
    totalImpactMet() {
      this.impact();
      this.totalImpact = parseFloat(this.radioDef[0].monthlyFee) + this.debitCardFeeImpact + this.creditCardFeeImpact + this.transactionFeeImpact + this.achFeeImpact + this.perCheckImpact;
      return this.totalImpact;
    },
    totalCurMet() {
      this.choose();
      // console.log('def --- ' + this.radioDef[0].monthlyFee)
      // console.log('random radio --- ' + this.radioCur[this.platformsList].monthlyFee)
      if ((this.platformsList === 10) && (this.platformsList === 11)) {
        // console.log(this.debitCardFeeImpact)
        if (isNaN(this.debitCardFeeImpact) || isNaN(this.creditCardFeeImpact)
          || isNaN(this.transactionFeeImpact) || isNaN(this.achFeeImpact) || isNaN(this.perCheckImpact)) {
          this.totalCur = 0;
          return 0;
        }
        this.totalCur = parseFloat(this.radioCur[this.platformsList].monthlyFee) + this.debitCardFeeCur + this.creditCardFeeCur + this.transactionFeeCur + this.achFeeCur + this.perCheckCur;
      } else {
        this.totalCur = parseFloat(this.radioCur[this.platformsList].monthlyFee) + this.debitCardFeeCur + this.creditCardFeeCur + this.transactionFeeCur + this.achFeeCur + this.perCheckCur;
      }
      return this.totalCur;
    },
    otherPlatform() {
      this.objOther.monthlyFee = this.radioCur[this.platformsList].monthlyFee;
      this.objOther.debitCardFee = this.radioCur[this.platformsList].debitCardFee;
      this.objOther.achFee = this.radioCur[this.platformsList].achFee;
      this.objOther.transactionFee = this.radioCur[this.platformsList].transactionFee;
      this.objOther.creditCardFee = this.radioCur[this.platformsList].creditCardFee;
      this.objOther.perCheck = this.radioCur[this.platformsList].perCheck;
      return this.radioCur[this.platformsList].name !== 'Other';
    },
    otherPlatformR() {
      this.objOther.monthlyFee = this.radioCur[this.platformsList].monthlyFee;
      this.objOther.debitCardFee = this.radioCur[this.platformsList].debitCardFee;
      this.objOther.achFee = this.radioCur[this.platformsList].achFee;
      this.objOther.transactionFee = this.radioCur[this.platformsList].transactionFee;
      this.objOther.creditCardFee = this.radioCur[this.platformsList].creditCardFee;
      this.objOther.perCheck = this.radioCur[this.platformsList].perCheck;
      return this.radioCur[this.platformsList].name !== 'Other ';
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../assets/sass/utils/variables';

  .price-comparison {
    position: relative;
    background: $color-white;
    width: 1164px;
    margin: auto;
    padding: 56px 30px 30px 30px;
    border-radius: 3px;
    max-height: 100%;

    &-bg {
      margin: auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-overlay {
      position: fixed;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      left: 0;
      top: 0;
      width: 100%;
      /*height: 100vh;*/
      height: 100%;
      background: rgba(0, 0, 0, 0.9);
      z-index: 9999;
      opacity: 1;
    }
    &__close {
      position: absolute;
      right: 21px;
      top: 18px;
      font-size: 14px;
      color: $color-pale-sky-dark;
      transition: opacity .3s ease;
      cursor: pointer;

      &:hover, &:focus {
        opacity: .6;
      }
    }

    &__desc {
      font-size: 11px;
      color: $color-pale-sky-dark;
      font-family: $font-global, sans-serif;
      margin-left: 70px;
      margin-right: 65px;
      line-height: 16px;

      a {
        display: inline-block;
        color: $color-curious-blue;
        transition: opacity .3s ease;

        &:hover, &:focus {
          opacity: .6;
        }
      }
    }

    &__title {
      font-family: $font-global-bold, sans-serif;
      color: $color-shark2;
      font-size: 32px;
      margin-bottom: 23px;
      text-align: center;
    }

    &__info {
      color: $color-dusty-gray3;
      text-align: center;
      font-family: $font-global, sans-serif;
      font-size: 19px;
      margin-bottom: 28px;
      line-height: 28px;

      span {
        font-family: $font-global-bold, sans-serif;
        font-size: 24px;
      }

      div {
        font-family: $font-global-bold, sans-serif;
        font-size: 19px;
      }
    }
  }

  .platforms {
    width: 944px;
    margin: auto;
    border: 1px solid $color-athens-gray;
    padding: 13px 30px 19px 30px;
    display: flex;
    position: relative;
    margin-bottom: 41px;
    border-radius: 5px;
    height: auto;

    &-listing {
      display: flex;
      flex-wrap: wrap;
      position: relative;

      &__save {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 62px;
        height: 32px;
        border-radius: 4px;
        color: $color-white;
        font-family: $font-global-medium, sans-serif;
        font-size: 14px;
        border: 1px solid $color-mischka;
        position: absolute;
        right: -7px;
        bottom: -2px;
        background: $color-pale-sky-dark;
        transition: background .3s ease, color .3s ease;

        &:hover, &:focus {
          background: $color-white;
          color: $color-pale-sky-dark;
        }
      }
    }

    &_hidden {
      background: $color-pale-sky-dark;
      padding-bottom: 25px;
      position: relative;

      &-close {
        position: absolute;
        right: 20px;
        top: 18px;
        font-size: 14px;
        color: $color-white;
        transition: opacity .3s ease;
        cursor: pointer;

        &:hover, &:focus {
          opacity: .6;
        }
      }

      .platforms-between {
        display: none;
      }
    }

    &-hidden {
      .platforms__title {
        color: $color-white;
        margin-bottom: 40px;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      margin-right: 30px;
      width: 245px;
      justify-content: flex-end;
      margin-bottom: 17px;

      &:nth-child(3n) {
        margin-right: 0;
        width: 200px;
      }

      span {
        font-family: $font-global-medium, sans-serif;
        font-size: 16px;
        color: $color-white;
        text-align: right;
        margin-right: 8px;
        position: relative;

        &:after {
          content: '%';
          position: absolute;
          right: -33px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 17px;
          font-family: "MullerMedium", sans-serif;
          color: $color-pale-sky-dark;
          opacity: .5;
        }
      }

      &:first-child, &:nth-child(4), &:nth-child(6) {
        span {
          &:after {
            content: '$';
            right: -27px;
          }
        }
      }

      input {
        font-size: 17px;
        color: $color-pale-sky-dark;
        font-family: "MullerMedium", sans-serif;
        border: 1px solid $color-geyser;
        text-align: right;
        padding-right: 15px;
        padding-left: 25px;
        width: 104px;
        height: 40px;
        border-radius: 4px;
        background: $color-white;
      }
    }

    &-content {
      position: relative;
      display: flex;
    }

    &-between {
      position: absolute;
      left: 56.5%;
      top: 50%;
      transform: translate(-50%, -50%);

      &:before {
        content: '';
        position: absolute;
        top: -70px;
        left: 8px;
        height: 70px;
        width: 1px;
        background: $color-alto-second;
      }

      &:after {
        content: '';
        position: absolute;
        top: 40px;
        left: 8px;
        height: 70px;
        width: 1px;
        background: $color-alto-second;
      }

      span {
        font-size: 20px;
        font-family: $font-global-medium, sans-serif;
        color: $color-pale-sky-dark;
      }

    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      margin: -15px ;

      &__item {
        padding: 15px 13px;
      }

    }

    &-left{
      width: 70%;
      position: relative;

      &__content {
        max-width: 485px;
      }

      .radio-item {
        &:nth-child(3n) {
          margin-left: 35px;
          margin-right: 0;
        }
      }
    }

    &-right {
      width: 36.8%;

      .platforms-list {
        justify-content: space-between;
      }

      .radio-item {
        &:nth-child(2n) {
          width: 90px;
        }
      }
    }

    &__title {
      color: $color-shark2;
      font-size: 18px;
      font-family: $font-global-semibold, sans-serif;
      margin-bottom: 29px;
    }

    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-shark2;
      font-size: 14px;
      font-family: $font-global-medium, sans-serif;
      background: $color-athens-gray2;
      border: 1px solid $color-mercury2;
      border-radius: 4px;
      height: 36px;
      width: 198px;
      position: relative;
      top: -3px;
      transition: background-color .2s ease-in, color .2s ease-in, border-color .2s ease-in;
      -webkit-appearance: none;

      &.disabled {
        background: $color-athens-gray;
        color: $color-ghost-second;
        border-color: $color-athens-gray;
        pointer-events: none;
      }
    }

  }

  .radio-item {
    display: block;
    position: relative;
    padding-left: 41px;
    cursor: pointer;
    user-select: none;
    color: $color-dusty-gray3;
    font-size: 16px;
    font-family: $font-global, sans-serif;
    padding-top: 1px;
    max-width: 147px;
    width: 100%;
    margin-right: 0;

    &_small {
      max-width: 103px;
      margin-bottom: 0;
      margin-top: 2px;
    }

    &__label {
      color: $color-dusty-gray3;
      transition: color .2s ease-in;
    }

  }

  .radio-item input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: $color-white;
    border: 1px solid $color-regent-gray;
    border-radius: 50%;
  }

  .radio-item:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .radio-item input:checked ~ .checkmark {
    background-color: $color-bright-turquoise;
    border-color: transparent;
  }

  .radio-item input:checked ~ .radio-item__label {
    color: $color-shark2;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .radio-item input:checked ~ .checkmark:after {
    display: block;
  }

  .radio-item .checkmark:after {
    content: '\E8BF';
    font-family: $icon-font;
    top: 50%;
    left: 50%;
    font-size: 10px;
    transform: translate(-50%, -50%);
    color: $color-white;
  }

  .savings {
    background: $color-bright-turquoise;
    padding: 6px 30px 35px 30px;
    position: relative;
    border-radius: 5px;
    margin: 0 73px;
    margin-bottom: 35px;

    &-table {
      position: absolute;
      /*bottom: 100%;*/
      background: $color-white;
      width: 320px;
      padding: 15px;
      text-align: center;
      /*top: 35px;*/
      left: auto;
      right: 43px;
      z-index: 1000;
      border-radius: 10px;
      background: $color-brink-pink;
      color: $color-white;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .5);
      overflow: hidden;
      bottom: 117px;
      top: auto;

      th {
        font-size: 16px;
        font-family: $font-global-medium, sans-serif;
        padding: 12px 15px;
        text-align: left;
      }

      tr {
        //border: 1px solid $color-loblolly;
        border: 1px solid rgba(48,49,51,.2);
        border-left: none;
        border-right: none;

        &:first-child {
          border-top: none;
        }

        &:last-child {
          border-bottom: none;
        }
      }

      td {
        padding: 10px 15px;
        text-align: left;
        font-size: 14px;
        font-family: $font-global, sans-serif;
      }
    }

    &__help {
      position: absolute;
      right: 9px;
      top: 7px;
      font-size: 20px;
      color: $color-white;
      cursor: pointer;
      transition: opacity .3s ease;

      &:hover, &:focus {
        opacity: .6;
      }
    }

    //&:after {
    //  content: '';
    //  position: absolute;
    //  bottom: -8px;
    //  left: 0;
    //  width: 100%;
    //  height: 8px;
    //  background: $color-white;
    //  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.2);
    //}

    &-between {
      position: absolute;
      left: 50%;
      top: 59%;
      transform: translate(-50%, -50%);

      &:before {
        content: '';
        position: absolute;
        top: -16px;
        left: 9px;
        height: 20px;
        width: 1px;
        background: $color-white;
      }

      &:after {
        content: '';
        position: absolute;
        top: 33px;
        left: 9px;
        height: 20px;
        width: 1px;
        background: $color-white;
      }

      span {
        font-size: 20px;
        font-family: $font-global-medium, sans-serif;
        color: $color-white;
      }

    }

    &-content {
      display: flex;
      position: relative;
    }

    &__title {
      font-size: 20px;
      font-family: $font-global-medium, sans-serif;
      color: $color-white;
      text-align: center;
      margin-bottom: 32px;
    }

    &__sum {
      font-family: $font-global-bold, sans-serif;
      font-size: 36px;
      color: $color-white;
      display: inline-block;
    }

    &__year {
      font-family: $font-global-medium, sans-serif;
      font-size: 20px;
      color: $color-white;
      display: inline-block;

      position: relative;
      top: 3px;
      left: 10px;
    }

    &-left {
      width: 50%;
      text-align: right;
      margin-right: 80px;
    }

    &-right {
      width: 50%;
      margin-right: 10px;
    }
  }

  .transactions {
    margin: 0 80px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;

    &-item{
      max-width: 452px;
      border: 1px solid $color-athens-gray;
      border-radius: 5px;
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;
      padding: 22px 16px;
      padding-bottom: 24px;

      &__slider {
        width: 260px;
        margin-right: 15px;
      }

      &__title {
        position: absolute;
        left: 9px;
        color: $color-cloud-burst;
        font-size: 18px;
        font-family: $font-global-semibold, sans-serif;
        background: $color-white;
        top: -22px;
        padding: 0 15px;
      }

      &__block {
        position: relative;

        &:after {
          content: '$';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 10px;
          font-size: 17px;
          font-family: $font-global-medium, sans-serif;
          color: $color-pale-sky-dark;
          opacity: .5;
        }
      }

      &__input {
        font-size: 17px;
        color: $color-shark2;
        font-family: $font-global-medium, sans-serif;
        border: 1px solid $color-mercury2;
        text-align: right;
        padding-left: 25px;
        padding-right: 16px;
        left: 25px;
        width: 136px;
        height: 40px;
        border-radius: 4px;
      }
    }
  }
  .error {
    border-color: $color-punch !important;
    background-color: $color-white !important;
  }

  .push-pay {
    position: absolute;
    top: 100px;
    left: 0;
    background: $color-pale-sky-dark;
    //border: 1px solid $color-athens-gray;
    box-shadow: 0 1px 6px 0 rgba(32,33,36,.5);
    border-radius: 5px;
    padding: 15px;
    z-index: 50;
    width: 276px;

    &__desc {
      color: $color-white;
      font-size: 18px;
      font-family: $font-global, sans-serif;
      margin-bottom: 29px;
      text-align: center;
    }

    &-item {
      display: flex;
      align-items: center;
      margin-right: 30px;
      width: 245px;
      justify-content: flex-end;
      margin-bottom: 17px;

      span {
        font-family: $font-global-medium, sans-serif;
        font-size: 16px;
        color: $color-white;
        text-align: right;
        margin-right: 8px;
        position: relative;

        &:after {
          content: '$';
          position: absolute;
          right: -33px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 17px;
          font-family: "MullerMedium", sans-serif;
          color: $color-pale-sky-dark;
          opacity: .5;
        }
      }

      input {
        font-size: 17px;
        color: $color-pale-sky-dark;
        font-family: "MullerMedium", sans-serif;
        border: 1px solid $color-geyser;
        text-align: right;
        padding-right: 15px;
        padding-left: 25px;
        width: 104px;
        height: 40px;
        border-radius: 4px;
        background: $color-white;
      }
    }

    &__save {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 62px;
      height: 32px;
      border-radius: 4px;
      color: $color-white;
      font-family: $font-global-medium, sans-serif;
      font-size: 14px;
      border: 1px solid $color-mischka;
      background: $color-pale-sky-dark;
      transition: background .3s ease, color .3s ease;
      cursor: pointer;
      margin-left: auto;

      &:hover, &:focus {
        background: $color-white;
        color: $color-pale-sky-dark;
      }
    }
  }

</style>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .transactions-item__slider {
    .vue-slider-component{
      .vue-slider-process{
        background-color:  $color-bright-turquoise;
        width: 122.5px;
      }

      .vue-slider {
        background-color: $color-mystic;
        height: 8px!important;
        border-radius: 10px;
      }

      .vue-slider-dot {
        border: 1px solid $color-mischka;
        background: linear-gradient(0deg, $color-catskill-white 0%, $color-twilight-blue 100%);
        border-radius: 4px;
        width: 16px !important;
        height: 24px !important;
        top: -8px !important;
        transform: translateX(114px);
      }
    }
  }

  .vue-slider-component .vue-slider-dot .vue-slider-dot-handle {
    box-shadow: none !important;
    background: none;
  }

  /*@media (max-width: 767px)  {*/
  /*  .price-comparison {*/
  /*    transform: scale(.5);*/
  /*  }*/
  /*}*/
</style>
