<template>
  <div
    v-shortkey="['esc']"
    class="modals-tv"
    @contextmenu.self.prevent
    @click="showModalTv = false"
    @shortkey="showModalTv = false"
  >
    <div
      class="modals-tv__content"
      @click.stop
      @contextmenu.prevent
    >
      <div
        class="modals-tv__close"
        @click="showModalTv = false"
      >
        <i class="icon-close-modal"></i>
      </div>
      <div class="modals-tv__title">
        <span>Reach millions of users worldwide</span>
        <span>with your own TV app</span>
      </div>
      <div class="modals-tv__desc">
        What’s included:
      </div>
      <div class="modals-tv-list">
        <div
          v-for="item in tvImg"
          :key="item.id"
          class="modals-tv__item"
        >
          <img
            :src="require('../../assets/images/' + item.img + '.png')"
            alt=""
          >
        </div>
      </div>
      <a
        href="/tv"
        class="modals-tv__btn"
        target="_blank"
      >
        Learn more
      </a>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  name: 'ModalsTV',
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      tvImg: [
        {
          id: 0,
          img: 'tv-list__img1',
        },
        {
          id: 1,
          img: 'tv-list__img2',
        },
        {
          id: 2,
          img: 'tv-list__img3',
        },
        {
          id: 3,
          img: 'tv-list__img4',
        },
      ],

    };
  },
  computed: {
    showModalTv: {
      get() {
        return this.$store.getters.showModalTv;
      },
      set(data) {
        this.$store.commit('setShowModalTv', data);
      },
    },
  },
};
</script>

<style lang="scss">
  @import '../../assets/sass/utils/variables';

  .modals-tv {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .1);

    &__content {
      max-width: 790px;
      width: 100%;
      border-radius: 10px;
      position: relative;
      background: $color-white;
      padding: 50px 35px 25px 35px;
      box-shadow: 0 16px 24px rgba(48, 49, 51, .2);
    }

    &__text {
      background: $color-white;
      overflow: hidden;
      padding: 10px;
    }

    &__close {
      position: absolute;
      top: 23px;
      right: 16px;
      font-size: 16px;
      color: $color-jumbo;
      transition: transform .15s ease-in;
      cursor: pointer;

      &:hover {
        transform: rotate(90deg);
      }
    }

    &__title {
      font-family: $font-global-bold;
      font-size: 31px;
      line-height: 40px;
      color: $color-shark;
      text-align: center;
      margin-bottom: 40px;

      span {
        display: block;
      }
    }

    &__desc {
      font-family: $font-global;
      color: $color-jumbo;
      font-size: 21px;
      line-height: 31px;
      text-align: center;
      margin-bottom: 50px;
    }

    &-list {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 44px;
      padding: 0 8px;
    }

    &__item {
      margin: 0 19px;
    }

    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 104px;
      height: 34px;
      border: 1px solid $color-jumbo;
      border-radius: 30px;
      color: $color-jumbo;
      font-family: $font-global-medium;
      font-size: 14px;
      cursor: pointer;
      margin: auto;
      transition: all .15s ease;
      overflow: hidden;
      position: relative;
      text-decoration: none;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        background: rgba(255, 255, 255, .5);
        opacity: 0;
        border-radius: 100%;
        transform: scale(1, 1) translate(-50%);
        transform-origin: 50% 50%;
      }

      @keyframes ripple {
        0% {
          transform: scale(0, 0);
          opacity: 1;
        }
        20% {
          transform: scale(25, 25);
          opacity: 1;
        }
        100% {
          opacity: 0;
          transform: scale(40, 40);
        }
      }

      &:focus:not(:active)::after {
        animation: ripple 2s ease-out;
      }

      &_extended {
        width: 166px;
      }

      &:hover, &:focus {
        background: $color-jumbo;
        color: $color-white;
      }
    }
  }

  @media (max-width: 767px) {
    .modals-tv {

      &__content {
        padding: 40px 15px;
      }
      &-list {
        flex-wrap: wrap;
        margin-bottom: 20px;
      }

      &__item {
        margin: 15px;
        max-width: 50%;
      }

      &__desc {
        margin-bottom: 20px;
        font-size: 18px;
      }

      &__title {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 20px;
      }
    }
  }
</style>
