<template>
  <div class="information">
    <div class="container">
      <div class="information-content">
        <div class="information-list">
          <div
            v-for="(item) in info"
            :key="item.id"
            class="information-item"
          >
            <div class="information-item__title">
              {{ item.title }}
            </div>
            <div class="information-item__value">
              {{ item.value }}
            </div>
            <div class="information-item__text">
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Information',
  data() {
    return {
      info: [
        {
          id: 0,
          title: 'COST CUTTING',
          value: '150%',
          text: 'Cost reduction',
        },
        {
          id: 1,
          title: 'IMPROVEMENT',
          value: '35%',
          text: 'Overall performance increase',
        },
        {
          id: 2,
          title: 'MORE GIVING',
          value: '20%',
          text: 'Donations increase',
        },
        {
          id: 3,
          title: 'REDUCED WASTED TIME',
          value: '8',
          text: 'Hours per week saved on management & reporting',
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      const wow = new window.WOW(
        {
          boxClass: 'wow',
          animateClass: 'animated',
          offset: 0,
          mobile: true,
          live: false,
          scrollContainer: null,
        },
      );
      wow.init();
    });
  },
  methods: {},
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .information {
    padding-top: 18px;
    padding-bottom: 37px;
    background: $color-gallery-dark;
    position: relative;

    &-content {
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin: 0 -15px;
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 285px;
      max-width: 285px;
      justify-content: center;
      padding: 10px;

      &:last-child {
        margin-top: 20px;
      }

      &__title {
        font-family: $font-global, sans-serif;
        font-size: 21px;
        line-height: 31px;
        color: $color-chateau-green;
      }

      &__value {
        font-family: $font-global-bold, sans-serif;
        color: $color-shark;
        font-size: 75px;
        line-height: 120px;
      }

      &__text {
        font-family: $font-global, sans-serif;
        font-size: 16px;
        line-height: 20px;
        color: $color-jumbo;
        text-align: center;
      }
    }
  }

  @media (max-width: 854px) {
    .information {
      &-list {
        position: relative;

        &:after {
          position: absolute;
          content: '';
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
          height: 1px;
          width: 97%;
          background: #D8D7D8;
        }
      }
    }
  }

    @media (max-width: 767px) {
    .information {

      &-list {
        margin: 0;
      }

      &-item {
        margin: 6px;

        &:last-child {
          margin-top: 6px;
        }

        &__title {
          font-size: 13px;
          line-height: 23px;
        }

        &__value {
          font-size: 54px;
          line-height: 83px;
        }

        &__text {
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
  }

  @media (max-width: 630px) {
    .information {

      &-list {
        margin: 0 -15px;

        &:after {
          width: 96%;
          top: 46%;
        }
      }

      &-item {
        min-width: auto;
        max-width: none;
        width: calc(50% - 20px);

        &__text {
          height: 30px;
        }
      }
    }
  }

  @media (max-width: 480px) {
    .information {
      padding-top: 9px;
      padding-bottom: 9px;
      overflow: hidden;

      &-list {
        margin: 0 -15px;
        position: relative;

        &:after {
          width: 95%;
        }
      }

      &-item {
        margin: 0;
        padding: 10px 0;

        &__title {
          text-align: center;
        }

        &:last-child {
          margin: 0;
        }

        &__text {
          height: 30px;
        }
      }
    }
  }

  @media (max-width: 370px) {
    .information {

      &-list {
        margin: 0 -25px;
      }

      &-item {
        padding: 10px 0;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .container {
    max-width: 1140px;
    width: 100%;
  }
</style>
