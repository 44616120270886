<template>
  <div
    id="bundles"
    class="bundles"
  >
    <div class="container">
      <h2 class="bundles__title">
        Bundles
      </h2>
      <div class="bundles__desc description">
        <span>Get the best value and price by bundling our solutions together.</span>
        <span>This is limited time offer pricing for churches & ministries with less than 1,000 attendees.</span>
        <span>Lock in a low price by signing up today. This pricing will not change for you ever. If you have </span>
        <span>1,000+ attendees or donors, please check our <a
          href="#"
          @click.prevent="showModalGetStarted = true"
        >Pricing page.</a></span>
      </div>
      <bundles-elements />
    </div>
  </div>
</template>

<script>
import bundlesElements from '@/components/bundlesElements';

export default {
  name: 'Bundles',
  components: {
    bundlesElements,
  },
  data() {
    return {
    };
  },
  computed: {
    showModalGetStarted: {
      get() {
        return this.$store.getters.showModalGetStarted;
      },
      set(data) {
        this.$store.commit('setShowModalGetStarted', data);
      },
    },
  },
  methods: {
  },
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .bundles {
    padding-top: 64px;
    padding-bottom: 59px;
    background-color: $color-white;
    position: relative;
    overflow: hidden;

    &__desc {
      margin-top: 9px;

      span {
        display: block;

        &:last-child {
          display: inline-block;
          margin-left: 153px;
        }
      }

      a {
        text-decoration: none;
        font-family: $font-global-bold, sans-serif;
        color: $color-royal-blue;
        margin-left: 4px;
        transition: opacity .3s ease;

        &:hover, &:focus {
          opacity: .6;
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .bundles {
      &__desc {
        span {
          &:last-child {
            margin-left: 0;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .bundles {
    }
  }

  @media (max-width: 480px) {
    .bundles {
      padding-top: 40px;
      padding-bottom: 25px;

      &__desc {
        font-size: 16px;
        line-height: 24px;
        margin-top: 17px;
        padding: 0 15px 20px 15px;
      }
    }
  }
</style>
