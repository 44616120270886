<template>
  <div>
    <transition
      appear
      name="fade-slide"
    >
      <next-generation
        :showModalVideo.sync="showModalVideoIndexPage"
        :hideVideoBlock="true"
      />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <DesignSite />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <Feedback />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <information />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <Bundles />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <hubspotForm3
        :appendForm="true"
      />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <Extension />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <Design
        :index2="true"
      />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <Integrations />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <hubspotForm3
        :formContainerId="'hubspotFormContainer2'"
      />
    </transition>
    <!--    <amoBtn />-->
  </div>
</template>

<script>
import nextGeneration from '@/components/nextGeneration';
// import Innovative from '@/components/innovative'
// import ControlMethod from '@/components/controlMethod'
// import Architecture from '@/components/architecture'
import DesignSite from '@/components/designSite';
// import BestPractices from '@/components/bestPractices'
import Extension from '@/components/extension';
import Feedback from '@/components/feedback';
import information from '@/components/information';
// import FeedbackMobile from '@/components/feedbackMobile'
import Bundles from '@/components/bundles';
import Design from '@/components/design';
import Integrations from '@/components/integrations';
// import Questions from '@/components/questions'
import hubspotForm3 from '@/components/hubspotForm3';
// import amoBtn from '@/components/amoBtn';

export default {
  name: 'Index2',
  components: {
    // Questions,
    Integrations,
    Design,
    Bundles,
    information,
    // FeedbackMobile,
    Extension,
    // BestPractices,
    // Architecture,
    // ControlMethod,
    // Innovative,
    nextGeneration,
    DesignSite,
    Feedback,
    hubspotForm3,
    // amoBtn,
  },
  props: ['showModalVideo'],
  data() {
    return {
      windowEl: window,
      windowOldScrollPosition: 0,
      setTimeoutId: 0,
      showContentImgScrollDown: true,
      showContentImgScrollUp: true,
    };
  },
  computed: {
    pricingObj: {
      get() {
        return this.$store.getters.computedPricingObj;
      },
      set(data) {
        this.$store.commit('setPricingObj', data);
      },
    },
    showModalVideoIndexPage: {
      get() {
        return this.showModalVideo;
      },
      set(data) {
        this.$emit('update:showModalVideo', data);
      },
    },
  },
  beforeMount() {
    const to = this.$route;
    const nearestWithMeta = to.meta.metaTags;
    nearestWithMeta.map((tagDef) => {
      const tag = document.createElement('meta');
      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });
      tag.setAttribute('data-vue-router-controlled', '');
      return tag;
    })
      .forEach((tag) => document.head.appendChild(tag));
  },
  beforeDestroy() {
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));
  },
  methods: {
    // isAnyPartOfElementInViewport: function (el) {
    //   const rect = el.getBoundingClientRect()
    //   const windowHeight = (window.innerHeight || document.documentElement.clientHeight)
    //   const windowWidth = (window.innerWidth || document.documentElement.clientWidth)
    //   const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0)
    //   const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0)
    //   return (vertInView && horInView)
    // },
    // windowIndexPageScroll: function () {
    //   this.$nextTick(() => {
    //     // let slideNumberTwo = document.getElementById('slideNumberTwo')
    //     let controlMethodContentImg = document.getElementById('controlMethodContentImg')
    //     // let scrollDirection
    //     // if (this.windowOldScrollPosition <= this.windowEl.scrollY) {
    //     //   scrollDirection = 'down'
    //     // } else {
    //     //   scrollDirection = 'up'
    //     // }
    //     if (this.isAnyPartOfElementInViewport(controlMethodContentImg)) {
    //       if (this.showContentImgScrollDown) {
    //         let srcContentImg = controlMethodContentImg.getAttribute('src').split('?')[0]
    //         srcContentImg += '?' + Math.random()
    //         controlMethodContentImg.setAttribute('src', srcContentImg)
    //         this.showContentImgScrollDown = false
    //       }
    //     } else {
    //       if (!this.showContentImgScrollDown) {
    //         this.showContentImgScrollDown = true
    //       }
    //     }
    //     // if (scrollDirection === 'down') {
    //     //   console.log(this.isAnyPartOfElementInViewport(controlMethodContentImg))
    //     //   if (this.isAnyPartOfElementInViewport(controlMethodContentImg)) {
    //     //     if (this.showContentImgScrollDown) {
    //     //       let srcContentImg = controlMethodContentImg.getAttribute('src').split('?')[0]
    //     //       srcContentImg += '?' + Math.random()
    //     //       controlMethodContentImg.setAttribute('src', srcContentImg)
    //     //       this.showContentImgScrollDown = false
    //     //     }
    //     //   } else {
    //     //     if (!this.showContentImgScrollDown) {
    //     //       this.showContentImgScrollDown = true
    //     //     }
    //     //   }
    //     // } else {
    //     //   console.log(this.showContentImgScrollUp)
    //     //   console.log(controlMethodContentImg.getBoundingClientRect())
    //     //   if (this.isAnyPartOfElementInViewport(controlMethodContentImg)) {
    //     //     if (this.showContentImgScrollUp) {
    //     //       let srcContentImg = controlMethodContentImg.getAttribute('src').split('?')[0]
    //     //       srcContentImg += '?' + Math.random()
    //     //       controlMethodContentImg.setAttribute('src', srcContentImg)
    //     //       this.showContentImgScrollUp = false
    //     //     }
    //     //   } else {
    //     //     if (!this.showContentImgScrollUp) {
    //     //       this.showContentImgScrollUp = true
    //     //     }
    //     //   }
    //     // }
    //     this.windowOldScrollPosition = this.windowEl.scrollY
    //     // if (slideNumberTwo !== undefined) {
    //     //   if (this.windowEl.scrollY <= slideNumberTwo.offsetTop && this.windowEl.scrollY !== 0) {
    //     //     if (this.setTimeoutId === 0) {
    //     //       if (scrollDirection === 'down') {
    //     //         this.scrollTo(this.windowEl, slideNumberTwo.offsetTop, 180, 'clear')
    //     //       } else {
    //     //         this.scrollTo(this.windowEl, 0, 180, 'clear')
    //     //       }
    //     //     } else {
    //     //       return true
    //     //     }
    //     //   } else {
    //     //     clearTimeout(this.setTimeoutId)
    //     //     this.setTimeoutId = 0
    //     //     this.windowOldScrollPosition = this.windowEl.scrollY
    //     //   }
    //     // }
    //   })
    // },
    // scrollTo: function (element, to, duration, clear) {
    //   if (duration === 0) {
    //     this.setTimeoutId = 0
    //     return
    //   }
    //   if (to < 0) to = 0
    //   let difference = to - element.scrollY
    //   let perTick = difference / duration * 10
    //   let thisEl = this
    //   if (clear) {
    //     clearTimeout(this.setTimeoutId)
    //     this.setTimeoutId = 0
    //   }
    //   this.setTimeoutId = setTimeout(() => {
    //     let newTop = element.scrollY + perTick
    //     if (newTop < 0) newTop = 0
    //     thisEl.windowOldScrollPosition = element.scrollY
    //     element.scrollTo({
    //       top: newTop
    //     })
    //     if (newTop === 0) {
    //       thisEl.setTimeoutId = 0
    //       return false
    //     }
    //     if (element.scrollY === to) {
    //       thisEl.setTimeoutId = 0
    //       return
    //     }
    //     thisEl.scrollTo(element, to, duration - 10)
    //   }, 10)
    // }
  },
};
</script>

<style lang="scss">
</style>
