<template>
  <div class="bundles-elements">
    <div
      v-for="(item, index) in bundlesElement"
      :key="index"
      class="bundles-elements-item"
      :class="[{'bundles-elements-item_active': index === 2}, {'bundles-elements-item_second': index === 1}, {'bundles-elements-item_first': index === 0}, {'bundles-elements-item_fourth': index === 3}]"
    >
      <div
        class="bundles-elements-item-top"
        :class="{'bundles-elements-item-top_subtext': item.subText}"
      >
        <div class="bundles-elements-item-top__title">
          {{ item.title }}

          <div
            v-if="item.optional"
            class="bundles-elements-item-top__optional"
          >
            {{ item.optional }}
          </div>
        </div>
        <template v-if="item.subText">
          <div
            v-for="(subText, index) in item.subText"
            :key="index"
            class="bundles-elements-item-top__mob"
          >
            {{ subText.text }}
            <div
              v-if="subText.optional"
              class="bundles-elements-item-top__optional"
            >
              {{ subText.optional }}
            </div>
          </div>
        </template>
        <div
          v-if="item.mobile"
          class="bundles-elements-item-top__mob"
        >
          {{ item.mobile }}
        </div>
        <div
          v-if="item.tv"
          class="bundles-elements-item-top__tv"
        >
          <i
            class="icon-info"
            @mouseenter="showModalTv = true"
          >
          </i>
          {{ item.tv }}
        </div>
        <div
          v-if="item.podcast"
          class="bundles-elements-item-top__podcast"
        >
          {{ item.podcast }}
        </div>
      </div>
      <div
        v-if="item.price"
        class="bundles-elements-item__price"
      >
        {{ item.price }}
      </div>
      <div
        v-if="item.setupFree"
        class="bundles-elements-item__setup-free"
      >
        {{ item.setupFree }}
      </div>
      <div class="bundles-elements-item__price-box">
        <div
          v-if="item.priceSetup"
          class="bundles-elements-item__price-setup"
        >
          {{ item.priceSetup }}
        </div>
        <div
          v-if="item.priceMonth"
          class="bundles-elements-item__price-month"
        >
          {{ item.priceMonth }}
        </div>
        <div
          v-if="item.priceOnly"
          class="bundles-elements-item__price-only"
        >
          {{ item.priceOnly }}
        </div>
      </div>
      <div
        class="bundles-elements-item__img"
        :class="item.classNameImg"
      >
        <img
          :src="require('../assets/images/' + item.img + '.svg')"
        >
      </div>
      <button
        class="bundles-elements-item__btn"
        :class="{'bundles-elements-item__btn_extended': index === 3}"
        @click.prevent="showPriceComparison(index)"
      >
        {{ item.btn }}
      </button>
      <a
        v-if="item.more"
        :href="item.link"
        class="bundles-elements-item__more"
        target="_blank"
      >
        {{ item.more }}
      </a>
      <div
        v-if="item.value"
        class="bundles-elements-item__value"
      >
        {{ item.value }}
      </div>
    </div>
    <transition
      name="animate-unfolding"
      appear
      mode="out-in"
    >
      <div
        v-if="showModalPriceComparison"
        v-shortkey="['esc']"
        class="modals-price-comparison"
        @shortkey="showModalPriceComparison = false"
      >
        <price-comparison :showModalPriceComparison.sync="showModalPriceComparison" />
      </div>
    </transition>
    <transition
      name="fade-block-wait"
      appear
      mode="out-in"
    >
      <modals-t-v
        v-if="showModalTv"
      >
      </modals-t-v>
    </transition>
  </div>
</template>

<script>
import priceComparison from '@/components/priceComparison';
import modalsTV from '@/components/modals/modalsTV';

export default {
  name: 'BundlesElements',
  components: {
    priceComparison,
    modalsTV,
  },
  data() {
    return {
      showModalPriceComparison: false,
      bundlesElement: [
        {
          title: 'Website',
          price: '$25/month',
          subText: [
            {
              text: '+CRM',
              optional: 'optional',
            },
            {
              text: '+Giving Platform',
              optional: 'optional',
            },
            {
              text: '+Media Platform',
            },
            {
              text: '+Forms',
            },
            {
              text: '+Graphics',
            },
          ],
          img: 'bundles__img-1',
          btn: 'Get Started',
          classNameImg: 'bundles-elements-item__img_first',
        },
        {
          // title: 'Website',
          subText: [
            {
              text: 'Website',
              optional: '+ Previous Package',
            },
          ],
          price: '$50/month',
          mobile: '+ Mobile App',
          img: 'bundles__img-2',
          btn: 'Get Started',
          more: 'Learn more',
          classNameImg: 'bundles-elements-item__img_second',
          link: '/mobile',
          setupFree: '$250 Setup Fee',
        },
        {
          // title: 'Website',
          subText: [
            {
              text: 'Website',
              optional: '+ Previous Package',
            },
          ],
          mobile: '+ Mobile App',
          tv: '+ TV App',
          podcast: '+ Podcast',
          price: '$100/month',
          img: 'bundles__img-3',
          btn: 'Get Started',
          value: 'Best Value',
          classNameImg: 'bundles-elements-item__img_third',
          setupFree: '$500 Setup Fee',
        },
        {
          title: 'Giving Platform',
          optional: 'optional',
          priceSetup: '$0 setup,',
          priceMonth: '$0/month,',
          priceOnly: 'only 2.3%',
          img: 'bundles__img-4',
          btn: 'Estimate Your savings',
          // more: 'Learn more',
          // link: '',
          classNameImg: 'bundles-elements-item__img_fourth',
        },
      ],
    };
  },
  computed: {
    showModalGetStarted: {
      get() {
        return this.$store.getters.showModalGetStarted;
      },
      set(data) {
        this.$store.commit('setShowModalGetStarted', data);
      },
    },
    showModalTv: {
      get() {
        return this.$store.getters.showModalTv;
      },
      set(data) {
        this.$store.commit('setShowModalTv', data);
      },
    },
    packageFirst: {
      get() {
        return this.$store.getters.packageFirst;
      },
      set(data) {
        this.$store.commit('setPackageFirst', data);
      },
    },
    packageSecond: {
      get() {
        return this.$store.getters.packageSecond;
      },
      set(data) {
        this.$store.commit('setPackageSecond', data);
      },
    },
    packageThird: {
      get() {
        return this.$store.getters.packageThird;
      },
      set(data) {
        this.$store.commit('setPackageThird', data);
      },
    },
  },
  methods: {
    showPriceComparison(index) {
      if (index === 3) {
        // console.log(this.$route)
        // history.push(null, null, 'price-comparison')
        // this.$router.push('/price-comparison')
        // console.log(this.$router.history)
        this.showModalPriceComparison = true;
      } else if (index === 0) {
        this.packageFirst = true;
        this.showModalGetStarted = true;
      } else if (index === 1) {
        this.packageSecond = true;
        this.showModalGetStarted = true;
      } else if (index === 2) {
        this.packageThird = true;
        this.showModalGetStarted = true;
      }
      // else {
      //   this.showModalGetStarted = true
      // }
      // console.log(this.showModalGetStarted)
    },
  },
};
</script>

<style lang="scss">
  @import '../assets/sass/utils/variables';
  .bundles {

    &-elements {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -30px;
      padding-top: 82px;

      &-item {
        background: $color-white;
        box-shadow: 0 8px 16px rgba(48, 49, 51, .2);
        border-radius: 6px;
        max-width: 262px;
        width: 100%;
        margin: 0 15px;
        padding: 24px;
        height: 462px;
        position: relative;
        cursor: pointer;
        transition: all .25s ease-in;

        &:hover, &:focus {
          transform: translateY(-8px) scale(1.05);
          box-shadow: 5px 16px 32px rgba(48, 49, 51, .2);
        }

        &_second {
          padding-top: 31px;

          .bundles-elements-item-top {
            margin-bottom: 8px;
          }
        }

        &-top{
          margin-bottom: 40px;

          &_subtext {
            margin-bottom: 8px;
          }

          /*&__tv  {
            position: relative;
            display: inline-block;

            &:after {
              content: '\E801';
              font-family: $icon-font;
              position: absolute;
              right: -31px;
              top: 0;
              font-size: 20px;
              color: $color-moody-blue;
            }
          }*/

          &__tv {
            display: block;

            &:after {
              right: auto;
              left: 86px;
            }
          }

          &__optional {
            font-style: italic;
            line-height: 18px;
            font-family: $font-global, sans-serif;
            font-size: 14px;
            text-align: right;
            padding-right: 2px;
            position: relative;
            top: -3px;
            left: -15px;
            color: $color-mischka;
          }
          &__title, &__mob, &__tv, &__podcast {
            display: flex;
            width: 100%;
            align-items: flex-end;
            justify-content: space-between;
            line-height: 24px;
            font-size: 18px;
            font-family: $font-global-medium, sans-serif;
          }

          &__title {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: flex-end;
          }

        }

        &__setup-free {
          display: flex;
          margin-top: 8px;
          width: 100%;
          align-items: flex-end;
          justify-content: space-between;
          line-height: 24px;
          font-size: 18px;
          font-family: $font-global-medium, sans-serif;
        }

        &__more {
          font-family: $font-global-medium, sans-serif;
          font-size: 14px;
          color: $color-jumbo;
          display: block;
          text-decoration: none;
          text-align: center;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 22px;
          transition: color .3s ease;

          &:hover, &:focus {
            color: $color-black;
          }
        }

        &__price {
          line-height: 48px;
          font-size: 37px;
          font-family: $font-global-bold, sans-serif;
          text-align: left;

          &-box {
            line-height: 32px;
            font-size: 24px;
            font-family: $font-global-bold, sans-serif;
          }
        }

        &_active {
          max-width: 278px;
          height: 492px;
          background: $color-mountain-meadow;
          margin: 0 7px;
          top: -19px;
          padding-left: 32px;
          padding-top: 27px;

          .bundles-elements-item-top__mob,
          .bundles-elements-item-top__tv {
            position: relative;
            i {
              cursor: pointer;
              position: absolute;
              top: 50%;
              left: 43%;
              transform: translate(-50%, -50%);
              color: $color-golden-fizz;
              font-size: 20px;
            }
            &:after {
              //color: $color-golden-fizz;
              display: none;
            }
          }

          .bundles-elements-item__price {
            color: $color-white;
          }

          .bundles-elements-item__btn {
            color: $color-white;
            border-color: $color-white;
            bottom: 24px;
          }

          .bundles-elements-item-top {
            margin-bottom: 8px;

            &__title, &__mob, &__tv, &__podcast {
              color: $color-white;
            }

          }

          .bundles-elements-item__setup-free {
            color: $color-white;
          }
        }

        &_fourth {
          .bundles-elements-item-top {
            margin-bottom: 13px;
          }
        }

        &__title {
          font-family: $font-global-bold, sans-serif;
          font-size: 21px;
          line-height: 31px;
        }

        &__img {
          text-align: center;
          margin: auto;
          position: relative;
          max-width: 180px;

          &_first {
            top: -20px;
            left: 8px;
          }

          &_second {
            top: 30px;
            left: 2px;
          }

          &_third {
            top: 29px;
            left: -12px;
          }

          &_fourth {
            top: 17px;
            left: -2px;
          }
        }

        &__value {
          position: absolute;
          bottom: -33px;
          left: 50%;
          transform: translateX(-50%);
          color: $color-mountain-meadow;
          font-family: $font-global-bold, sans-serif;
          font-size: 18px;
        }

        &__btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 101px;
          height: 34px;
          border: 1px solid $color-french-rose;
          border-radius: 30px;
          color: $color-crimson;
          font-family: $font-global-medium;
          font-size: 14px;
          cursor: pointer;
          margin: auto;
          transition: all .15s ease;
          position: absolute;
          bottom: 54px;
          left: 50%;
          transform: translateX(-50%);
          overflow: hidden;

          &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 10px;
            height: 10px;
            background: rgba(255, 255, 255, .5);
            opacity: 0;
            border-radius: 100%;
            transform: scale(1, 1) translate(-50%);
            transform-origin: 50% 50%;
          }

          @keyframes ripple {
            0% {
              transform: scale(0, 0);
              opacity: 1;
            }
            20% {
              transform: scale(25, 25);
              opacity: 1;
            }
            100% {
              opacity: 0;
              transform: scale(40, 40);
            }
          }

          &:focus:not(:active)::after {
            animation: ripple 2s ease-out;
          }

          &_extended {
            width: 166px;
          }

          &:hover, &:focus {
            background: $color-crimson;
            color: $color-white;
            border-color: $color-crimson;
          }
        }
      }
    }
  }

  @media (max-width: 1199px) {
    .bundles-elements {
      &-item, &-item_active {
        /*margin: 0 33px;*/
      }

      &-item {
        max-width: 300px;
        margin-bottom: 15px;
      }

      &-item_active {
        max-width: 316px;
      }
    }
  }

  @media (max-width: 991px) {
    .bundles-elements {
      justify-content: center;
      &-item {
        margin-bottom: 30px;
      }
    }
  }

  @media (max-width: 480px) {
    .bundles-elements {
      margin: 0;
      padding-top: 25px;

      &-item {
        margin-bottom: 30px;
        max-width: 262px;
        width: 100%;
        height: 427px;

        &_first {
          //order: -3;
          height: 428px;

          .bundles-elements-item__btn {
            bottom: 16px;
          }
        }

        &_second {
          //order: -1;
          padding-top: 28px;
          height: 456px;
          margin-bottom: 24px;

          .bundles-elements-item__btn {
            bottom: 50px;
          }

          .bundles-elements-item__more {
            bottom: 18px;
          }
        }

        &_fourth {
          //order: -2;
          padding-top: 21px;
          height: 456px;

          .bundles-elements-item__btn {
            bottom: 50px;
          }

          .bundles-elements-item__more {
            bottom: 18px;
          }
        }

        &_active {
          //order: -4;
          padding-top: 25px;
          top: 0;
          height: 468px;
          max-width: 278px;
          margin-bottom: 64px;

          .bundles-elements-item__btn {
            bottom: 17px;
          }
        }

        &__img {
          &_third {
            top: 25px;
          }

          &_first {
            left: 11px;
          }
        }

        &__btn {
          bottom: 16px;
        }
      }
    }
  }
</style>
